import React, {useState} from "react";
import {Alert, Button} from 'react-bootstrap';

function AlertDismissible(props) {
    let type = "primary"
    const [show, setShow] = useState(true);
    switch (props.type) {
        case "success":
            type = "success";
    }
    return (
        <>
            <Alert show={props.show} variant={props.typeClass}>
                <Alert.Heading>{props.title}</Alert.Heading>
                <p>
                    {props.message}
                </p>
                <hr/>
                <div className="d-flex justify-content-end">
                    <Button onClick={props.hideFunc} variant={props.typeClass}>
                        ok
                    </Button>
                </div>
            </Alert>

        </>
    );
}

export default AlertDismissible;