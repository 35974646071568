import React, {useEffect, useState} from "react";
import Aux from "../../../hoc/Auxilary/Auxilary";
import {Dimmer, Dropdown, Loader} from "semantic-ui-react";
import Classes from "../Search/search.module.css"
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import axios from "../../../axios-agent";
import CLasses from "../../register/register.module.css";
const SearchPage = (props) => {
    const [causes,setCauses] = useState([])
    const [loading,setLoading] = useState(false)
    const [body,setBody] =useState(<div>Loading ... </div>)
    const [searchTerm, setSearchTerm] = useState(<div></div>)
    useEffect(()=>{
        const delayDebounceFn = setTimeout(() => {
            // Send Axios request here
            // axios.defaults.headers.common['x-api-key'] = "6oHDhYt5mW0rcL2ynt4Thw1mUhaLtZOXynvNW8DVfVtc57gMgZ2g55Y2mYfW"
            if(searchTerm!==""){
                setLoading(true)
                let encoded = base64_encode(searchTerm);
                axios.get('/cause/search?input='+encoded).then(response => {
                    setLoading(false)
                    const tempCauseList=response.data
                    let causeList=[]
                    tempCauseList.map(cauesTemp=>{
                        let tmpCause= {
                            key: cauesTemp.key,
                            text: cauesTemp.value,
                            value: cauesTemp.value,
                            logo_url:cauesTemp.logo_url,
                            image: {  avatar: true, src: cauesTemp.image }

                        };
                        causeList.push(tmpCause)

                    })
                    setCauses(causeList)
                }).catch(err=>{
                    console.log(err)
                    setLoading(false)
                })
            }

        }, 1000)

        return () => clearTimeout(delayDebounceFn)
    },[searchTerm])
    useEffect(()=>{
        console.log(causes)
        setBody(   <div className>
            <div className={Classes.searchBox}>
                <h4>Search</h4>
                <Loader active={loading} inline='centered' className={Classes.loaderBox} />

                <Dropdown

                    onSearchChange={(e) => setSearchTerm(e.target.value)}
                    placeholder
                    fluid
                    search
                    selection
                    options={causes}
                />
            </div>


        </div>)
    },[causes,loading])
    return (
      <Aux>
          {body}

  </Aux>)
}
export default SearchPage;