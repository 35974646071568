import React, {Component} from "react";
import Aux from "../../hoc/Auxilary/Auxilary";
import 'font-awesome/css/font-awesome.min.css';
import CLasses from './register.module.css';
import crypto from 'crypto-js';

import SocialButton from "../../Components/Social/social/SocialButton";
import SmallWindowBox from "../../Components/ui/SmallWindowBox/SmallWindowBox";
import DefaultRegister from "../../Components/Register/DefaultRegister";
import axios from '../../axios-agent';
import AlertDialog from '../../Components/ui/alert/alert';
import {Button, Spinner} from "react-bootstrap";
import Cookies from 'universal-cookie';
import {withRouter} from "react-router-dom";

class Register extends Component {

    authHandler = (err, data) => {
        console.log(err, data);
    };
    tempCauseList = [{
        "value": "Alzheimer's Association",
        "text": "Alzheimer's Association",
        "key": "alz",
        "logo_url": "",
        "image": { avatar: true, src: "https://dev.givingforward.org/logo.png" }
    },
    ];
    causeList = [
    ];

    cause = "";
    userCause = {
        userid: "",
        cause: ""
    };
    userSite = {
        firstname: "",
        lastname: "",
        avatar: "",
        name: "",
        email: "",
        hash: "",
        cause: "",
        readerpoint: 0,
        dayilypoint: 0,
        social: false,
        site:""
    };
    alertMessage = {
        title: "",
        typeClass: "success",
        message: ""
    };

    constructor(props) {
        super(props);
        const cookies = new Cookies();
        let defaultCause = this.getCookie('defaultCause');
        if (defaultCause == undefined) {
            defaultCause = "gf"
        }
        this.userSite.cause = defaultCause;
        this.state = {
            defaultCauseCell:{
                value: "General Fund",
                text: <span><img src="https://dev.givingforward.org/logo.png" className={CLasses.logoCause}/> General Fund</span>,
                key: "gf",
            },
            cause: defaultCause,
            loadingCause: true,
            socialRegisterFinished: false,
            success: false,
            showAlert: false,
            loading: false,
            registered: false,
            userState: {
                avatar: "",
                name: "",
                email: "",
                hash: "",
                cause: defaultCause,
                readerpoint: 0,
                dayilypoint: 0
            },
            user: {},
            logged: false,
            currentProvider: ''
        };
        this.nodes = {};

        this.onLoginSuccess = this.onLoginSuccess.bind(this);
        this.onLoginFailure = this.onLoginFailure.bind(this);
        this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
        this.onLogoutFailure = this.onLogoutFailure.bind(this);
        this.logout = this.logout.bind(this)
    }


    removeCharAt= (input,i) =>{
        var tmp = input.split(''); // convert to an array
        tmp.splice(i - 1 , 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
        return tmp.join(''); // reconstruct the string
    }
    setCookie(key,value){
        const cookies = new Cookies();
        cookies.set(key, value, {path: '/',secure:true,sameSite:'none'});
        localStorage.setItem(key, value);
    }
    setCookiesForAuth(hash, token) {
        this.setCookie('userid', hash)
        this.setCookie('token', token)
    }

    showAlert(title, message, type) {

        this.alertMessage.message = message;
        this.alertMessage.title = title;
        this.alertMessage.typeClass = type;
        this.setState({
            showAlert: true
        });
    }

    btnAlertClicked = () => {
        this.setState({
            showAlert: false
        });
    };

    registerNameChanged = (event) => {
        this.setState({
            showAlert: false
        });
        // this.setState({userState:this.userSite})
        this.userSite.name = event.target.value;
    };

    registerEmailChanged = (event) => {
        this.setState({
            showAlert: false
        });
        // this.setState({userState:this.userSite})
        this.userSite.email = event.target.value;
    };

    registerCauseChanged = (event) => {
        console.log(event.target);
        this.setState({
            showAlert: false
        });
        this.cause = event.target.textContent;
        console.log(this.cause);
    };

    setNodeRef(provider, node) {
        if (node) {
            this.nodes[provider] = node
        }
    }

    validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    onLoginFailure(err) {
        console.error(err);

        this.setState({
            logged: false,
            currentProvider: '',
            user: {}
        });
    }

    onLogoutSuccess () {
        this.setState({
            logged: false,
            currentProvider: '',
            user: {}
        })
    }
    getCookie(key){
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){
            value=cookies.get(key+'-planb');
        }
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    onLogoutFailure(err) {
        console.error(err)
    }

    logout() {
        const {logged, currentProvider} = this.state;

        if (logged && currentProvider) {
            this.nodes[currentProvider].props.triggerLogout()
        }
    }

    onLoginSuccess(user) {
        if (user == undefined) {
            this.showAlert("Error", "Internal server error, please try again later.", "danger");

        } else {
            this.setState({loading: false});
            this.userSite.avatar = user._profile.profilePicURL;
            this.userSite.firstname = user._profile.firstName;
            this.userSite.lastname = user._profile.lastName;
            this.userSite.email = user._profile.email;
            this.userSite.name = user._profile.name;
            this.userSite.hash = crypto.MD5(this.userSite.email).toString();
            this.userSite.social = true;
            axios.post('/tb/register', this.userSite).then(response => {
                console.log(response)
                this.setState({loading: false});
                if (response.status) {
                    this.setCookiesForAuth(this.userSite.hash, response.data.token);
                }
                this.showAlert("Success", "Registration was successful", "success");
                this.setState({success: true});
                //.....................................
                var tags = [
                    domain,
                    response.data.cause,
                    "reader"
                ];
                const mailchimp = {
                    firstname: response.data.firstname,
                    lastname: response.data.lastname,
                    email:  response.data.email,
                    tags: tags,
                    status: "subscribed"
                };
                axios.post('/mailchimp', mailchimp).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log(error)
                });
                //.....................................
                if (!response.data.registered) {
                    if (domain===""){
                        domain="public"
                    }

                    this.props.history.push("/cause");
                } else {
                    this.setState({loading: false});
                    let domain= this.getHostedSite()+'c/?userid='+this.userSite.hash+'&token='+response.data.token
                    window.location.replace(domain)
                    //window.close()
                }
            })
                .catch(error => {
                    console.log(error);
                    if (error.status) {
                        this.showAlert("Error", error.response.data.message, "danger");

                    } else {
                        this.showAlert("Error", "Internal server error, please try again later.", "danger");

                    }
                    this.setState({loading: false});
                    console.log(this.state.loading)
                });
            let domain = this.state.domain;


            this.setState({
                logged: true,
                currentProvider: user._provider,
                user
            })
        }
    }
     removeCharAt= (input,i) =>{
        var tmp = input.split(''); // convert to an array
        tmp.splice(i - 1 , 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
        return tmp.join(''); // reconstruct the string
    }
    getCauseKey() {

            let causeNew=this.removeCharAt(this.cause)
        this.causeList.map(cause => {
            if (cause.value.includes(causeNew)) {
                this.userSite.cause = cause.key;
                this.setState({cause: cause.key});
            }
        });
    }
    getHostedSite(){
        let domain=window.location.hostname;
        domain= domain.replace('i.','')
        return "https://"+domain+"/"

    }
    addOrReplaceParam = (url, param, value) => {
        param = encodeURIComponent(param);
        var r = "([&?]|&amp;)" + param + "\\b(?:=(?:[^&#]*))*";
        var a = document.createElement('a');
        var regex = new RegExp(r);
        var str = param + (value ? "=" + encodeURIComponent(value) : "");
        a.href = url;
        var q = a.search.replace(regex, "$1" + str);
        if (q === a.search) {
            a.search += (a.search ? "&" : "") + str;
        } else {
            a.search = q;
        }
        return a.href;
    }
    registerDefaultHandler = () => {
        if (this.userSite.email == "") {
            this.showAlert("Error", "email could not empty.", "danger");
            this.setState({loading: false});
            return;
        }
        if (!this.validateEmail(this.userSite.email)) {
            this.showAlert("Error", "Email is not valid.", "danger");
            return;
        }
        this.setState({loading: true});
        this.getCauseKey();
        if (this.userSite.avatar == "") {
            this.userSite.avatar = 'https://www.dev.givingforward.org/avatar.png';
        }
        if (this.userSite.cause == "" || this.userSite.cause == undefined) {
            const cookies = new Cookies();
            let defaultCause = this.getCookie('defaultCause');
            if (defaultCause===undefined ){
                defaultCause=localStorage.getItem('defaultCause');

            }
            if (defaultCause == undefined) {
                defaultCause = "gf"
            }
            this.userSite.cause = defaultCause
        }
        this.userSite.site=window.location.hostname;
        console.log(this.userSite)
        this.userSite.hash = crypto.MD5(this.userSite.email).toString();
        axios.post('/tb/register', this.userSite).then(response => {
            this.userSite.email = "";
            console.log(response);
            this.setState({loading: false});
            this.setState({registered: true});
            this.showAlert("Success", response.data.message, "success");


        })
            .catch(error => {
                console.log(error);
                if (error.status) {
                    this.showAlert("Error", error.response.data.message, "danger");

                } else {
                    this.showAlert("Error", "Internal server error, please try again later.", "danger");

                }
                this.setState({loading: false});
                console.log(this.state.loading)
            });
    };


    componentDidMount() {

        let path = this.props.location.search;
        console.log(path);
        let domain = path.replace("?domain=", "");
        this.setState({domain: domain});
        axios.get('/cause').then(response => {
            this.tempCauseList = response.data;

            this.tempCauseList.map(caues=>{
                let tmpCause= {
                        key: caues.key,
                        text: <span><img src={caues.image} className={CLasses.logoCause}/> {caues.text}</span>,
                        value: caues.value,
                    };
                this.causeList.push(tmpCause)
                const defaultCause = this.getCookie("defaultCause")

                if (caues.key===defaultCause){
                    const selectedCell={
                        value: caues.value,
                        text: <span><img src={caues.image} className={CLasses.logoCause}/>{caues.value}</span>,
                        key: caues.key,
                    }
                    this.setState({defaultCauseCell:selectedCell})
                }
            })
            this.setState({loadingCause: false});
            console.log(response);
        }).catch(error => {
            console.log(error);
            this.setState({loadingCause: false})

        });
        console.log(this.causeList)
    }



    render() {
        console.log(this.state.success);
        let Content;
        let loading;
        if (this.state.loading) {
            loading = <div className={CLasses.centerDiv}>
                <hr></hr>
                <h6>Please wait...</h6>
                <Spinner className animation="border" variant="success"/>
            </div>
        }
        if (this.state.success || this.state.logged) {
            Content = <div className={CLasses.centerDiv}>
                <hr></hr>
                <h6>Please wait...</h6>
                <Spinner className animation="border" variant="success"/>
            </div>;

        }
        if (this.state.registered) {
            Content = <div>
                <SmallWindowBox>
                    <h2 className={CLasses.centerDiv}>congratulations</h2>
                    <hr></hr>
                    <div className={CLasses.centerDiv}>
                        <p>
                            Please check your email. You will receive a message confirming your email address.
                        </p>
                    </div>
                    <Button onClick={() => {
                        window.close()
                    }} variant="success">Close</Button>
                </SmallWindowBox>
            </div>
        }
        if (this.state.loadingCause) {
            Content = <div className={CLasses.centerDiv}>
                <hr></hr>
                <h6>Please wait...</h6>
                <Spinner className animation="border" variant="success"/>
            </div>;
        }
        if (!this.state.logged && !this.state.registered &&
            !this.state.success && !this.state.loadingCause) {
            Content =
                <div>
                    <SmallWindowBox>
                        <h2 className={CLasses.centerDiv}>Register</h2>
                        <hr></hr>
                        <DefaultRegister
                            cause={this.userSite.cause}
                            nameChanged={this.registerNameChanged}
                            emailChanged={this.registerEmailChanged}
                            causeChanged={this.registerCauseChanged}
                            email={this.userSite.email}
                            name={this.userSite.name}
                            causeList={this.causeList}
                            defaultValue={this.state.defaultCauseCell.value}
                            registerHandler={this.registerDefaultHandler}>

                        </DefaultRegister>
                        {loading}
                        <hr className={CLasses.hrCustom}></hr>
                        <div>
                            <SocialButton
                                className={CLasses.buttonWidth}
                                provider='google'
                                type="google"
                                appId='580879083076-sfkb4hksl6soj3nbhlk8tpe4oi644mmf.apps.googleusercontent.com'
                                onLoginSuccess={this.onLoginSuccess}
                                onLoginFailure={this.onLoginFailure}
                                onLogoutSuccess={this.onLogoutSuccess}
                                onLogoutFailure={this.onLogoutFailure}
                                getInstance={this.setNodeRef.bind(this, 'google')}
                                key={'google'}
                            >
                                Register with Google
                            </SocialButton>
                            <br></br>
                            <SocialButton
                                className={CLasses.buttonWidth}
                                provider='facebook'
                                type="facebook"
                                appId="369371820691717"
                                onLoginSuccess={this.onLoginSuccess}
                                onLoginFailure={this.onLoginFailure}
                                onLogoutSuccess={this.onLogoutSuccess}
                                getInstance={this.setNodeRef.bind(this, 'facebook')}
                                key={'facebook'}
                            >
                                Register with Facebook
                            </SocialButton>
                        </div>
                        <hr></hr>
                        {/*  <div className={CLasses.centerDiv} >
                            <img className={CLasses.logoButton} src={Logo}></img>

                        </div>*/}

                        <AlertDialog
                            show={this.state.showAlert}
                            title={this.alertMessage.title}
                            hideFunc={this.btnAlertClicked}
                            typeClass={this.alertMessage.typeClass}
                            message={this.alertMessage.message}></AlertDialog>
                    </SmallWindowBox>

                </div>


        }


        return (<Aux>
                {Content}

            </Aux>

        );
    }
}

export default withRouter(Register);