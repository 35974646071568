import React from 'react';
import './App.css';
import Register from "./Pages/register/register";
import {Route, Switch} from 'react-router-dom';
import Topbar from './Pages/topbar/topbar'
import MoonNotfound from "./Pages/notfound/notfound";
import Internalerror from "./Pages/internalerror/internalerror";
import Success from "./Components/ui/Success/Success";
import CauseChange from "./Pages/Cause/CauseChange/CauseChange";
import SocialSharing from "./Pages/SocialSharing/SocialSharing";
import Profile from "./Pages/profile/old-profile";
import Email from "./Pages/email/email"
import FeaturedRegister from "./Pages/feathred-register/FeaturedRegister";
import Userprofile from "./Pages/profile/Profile";
import SearchPage from "./Pages/Cause/Search/search";

function App() {
    return (
        <Switch>
            <Route path="/old-register" component={Register}/>
            <Route path="/cause" component={CauseChange}/>
            <Route path="/notfound" exact component={MoonNotfound}/>
            <Route path="/error" exact component={Internalerror}/>
            <Route path="/success/:id/:token" component={Success}/>
            <Route path="/social" component={SocialSharing}/>
            <Route path="/profile" component={Userprofile}/>
            <Route path="/old_profile" component={Profile}/>
            <Route path="/email" component={Email}/>
            <Route path="/search" component={SearchPage}></Route>
            <Route path="/register" render={props=><FeaturedRegister {...props}/>}/>
            <Route path="/" exact component={Topbar}/>
        </Switch>
    );
}

export default App;
