import React, {Component} from "react";
import {Button, Form} from 'react-bootstrap';
import Classes from '../Register/DefaultRegister.module.css';
import {Dropdown} from "semantic-ui-react";
import Cookies from "universal-cookie";

class DefaultRegister extends Component {
    constructor(props) {
        super(props);
        const cookies = new Cookies();
        let defaultCause = this.getCookie('defaultCause');
        if (defaultCause == undefined) {
            this.setState({defaultCause: defaultCause})

        } else {
            this.setState({defaultCause: ""})
        }
    }

    handleChange = (e, {value}) => this.setState({value})
    getCookie(key){
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }

    render() {


        return (
            <div>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter your name." onChange={this.props.nameChanged}/>
                    <Form.Text className="text-muted">
                        Register so that we can count your points toward your cause.
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" required placeholder="Enter email" onChange={this.props.emailChanged}/>
                    <Form.Text className="text-muted">
                        We'll al send you the occasional email.<a style={{textDecoration: "underline"}} href="https://www.givingforward.org/privacy/" target="_blank"> Privacy Policy.</a>
                    </Form.Text>
                </Form.Group>
                <Form.Label>Cause </Form.Label>
                <Dropdown
                    onChange={this.props.causeChanged}
                    placeholder="Please Select Cause"
                    fluid
                    search
                    selection
                    defaultValue={this.props.defaultValue}
                    options={this.props.causeList}
                />
                <br></br>
                <div className={Classes.centerDiv}>
                    <Button className={Classes.buttonWidth}
                            onClick={this.props.registerHandler}
                            variant="success" type="submit">
                        Register
                    </Button>
                </div>

            </div>);
    }

}

export default DefaultRegister;



