import React from 'react'
import SocialLogin from 'react-social-login'
import 'semantic-ui-css/semantic.min.css'
import Classes from '../social/SocialButton.module.css';
import {Button, Icon} from 'semantic-ui-react'

class SocialButton extends React.Component {

    showButton(type) {
        switch (type) {
            case 'google':
                return (
                    <div className={Classes.centerDiv}><Button color='google plus'
                                                               onClick={this.props.triggerLogin} {...this.props}>
                        <Icon name='google'/>
                        {this.props.children}
                    </Button></div>)

            case 'facebook':
                return (
                    <div className={Classes.centerDiv}><Button color='facebook'
                                                               onClick={this.props.triggerLogin} {...this.props}>
                        <Icon name='facebook'/>
                        {this.props.children}
                    </Button>
                    </div>)

            case 'twitter':
                return (<Button color='twitter'
                                onClick={this.props.triggerLogin} {...this.props}>
                    <Icon name='twitter' />
                    { this.props.children }
                </Button>)



        }
    }
    componentDidMount() {
        console.log(this.showButton());
    }

    render() {


        return (
           <div>
               {this.showButton(this.props.type)}
           </div>
        );
    }
}

export default SocialLogin(SocialButton);