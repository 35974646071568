import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Aux from '../../hoc/Auxilary/Auxilary';
import {Button, Nav, Navbar, Row} from 'react-bootstrap';
import AvatarDropdown from "./AvataDropDown/avtarDropdown";
import Cookies from "universal-cookie";
import axios from '../../axios-agent';
import Spinner from "react-bootstrap/Spinner";
import LogoTop from '../../assets/images/logo512.png';
import UserData from "../../Components/ui/UserData/UserData";
import Classes from './topbar.module.css';
import CLasses from "./topbar.module.css";
import FollowButton from "../../Components/ui/FollowButton/FollowButton";

class Topbar extends Component {
    state = {
        domain: "",
        loading: true,
        url: "",
        hash: "",
        token: "",
        btnRegister: false,
        register: false,
        user: {
            avatar: "",
            email: "",
            hash: "",
            cause: "",
            readerpoint: 0,
            dayilypoint: 0
        }
        , defaultCause: ""

    };
    removeCharAt= (input,i) =>{
        var tmp = input.split(''); // convert to an array
        tmp.splice(i - 1 , 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
        return tmp.join(''); // reconstruct the string
    }
    isSafari=()=>{
        if (navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("Chrome") === -1){
        }
    };

    isRegistred = () => {
        var hashContent = "";
        var tokenContent = "";
        const cookies = new Cookies();
        hashContent =  this.getCookie('userid');
        tokenContent =  this.getCookie('token');

        if (hashContent==="" || hashContent===undefined){
            hashContent= localStorage.getItem('userid');
            tokenContent= localStorage.getItem('token');
        }
        var auth = {
            userid: hashContent,
            token: tokenContent
        };


        axios.post('/auth', auth).then(response => {
            let name = "Dear friend!";
            if (response.data.first_name != "") {
                const names = response.data.first_name.split(' ');
                name = names[0];
            }
            this.setState({
                user: {
                    name: name,
                    avatar: response.data.avatar,
                    cause: response.data.cause,
                    readerpoint: response.data.reader_point,
                    dayilypoint: this.state.user.dayilypoint
                }
            });
            this.setCookie("cause",response.data.cause)
            this.setState({register: true, loading: false, btnRegister: false});
            this.setCookie('avatar',this.state.user.avatar)
        }).catch(error => {
            this.setState({register: false, loading: false, btnRegister: true});

        });

    };
    /*
        Our stored identity data is stored in three ways.
        1.Iframe’s cookie
        2.Localstorage’s cookie
        3.Publisher site cookie
        For this reason, every time we want to receive information about userid and token
        , we check the priority of all three sources,
        respectively.Just remember that in the third case a certain amount of "-planb"
        is added to the end of the stored key.
     */
    getCookie(key){
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){

            value=cookies.get(key+'-planb');
        }
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    /*
    If the user is registered and there is a cookie, it will be checked and if
    the information is correct, the topbar status will change to registered.
    If the user is logouted, the topbar status is also corrected.
     */
    isCookieSet() {
        let cause = this.getCookie('cause');
        let user = this.state.user
        if (cause !== this.state.user.cause && cause != undefined) {
            user.cause = cause
            this.setState({user: user})
        }
        if (!this.state.register) {
            let hashContent = "";
            hashContent = this.getCookie('userid');
            if (hashContent != undefined) {
                this.isRegistred();
            }
        }
        if (this.state.register){
            let hashContent = this.getCookie('userid')
            if (hashContent===null){
                this.setState({
                    register:false,
                    btnRegister:true
                })
            }
        }
    }

    setCookie(key,value){
        const cookies = new Cookies();
        cookies.set(key, value, {path: '/',secure:true,sameSite:'none'});
        // let domainName=window.location.hostname
        // domainName=this.removeCharAt(domainName)
        // cookies.set(key, value, {domain: domainName,secure:true,sameSite:'none'});
        localStorage.setItem(key, value);
    }
    /*
     Receive the month number of the year from the standard date function. For example,
     January becomes one. Receive the value stored in the cookie with the month key.
     If the value stored in the cookie and the current month of the year are different,
     the month value in the cookie will be updated. The monthlypoint value in the cookie should also be queued.
     If the amount stored in the cookie and the current month of the year are the same,
     add six units to the monthlypoint value stored in the cookie and update.
     */
    incrementDailyPoint = () => {
        let cookieDay, DailyPoint, day;
        var today = new Date();
        const cookies = new Cookies();
        cookieDay =  this.getCookie('month');
        DailyPoint =  this.getCookie('dayilypoint');
        if (cookieDay == undefined) {
            cookieDay = today.getMonth();
            this.setCookie('month', cookieDay);
        }
        if (DailyPoint == undefined) {
            DailyPoint = 6;
        }
        day = today.getMonth();
        if (day != cookieDay) {
            cookieDay = today.getMonth();
            this.setCookie('month', cookieDay);
            DailyPoint = 6;
        }
        DailyPoint = parseInt(DailyPoint) + 6;

       this.setCookie('dayilypoint', DailyPoint);

    };

    getUrlVars(url) {
        var vars = {};
        url = decodeURI(url)
        var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    componentDidMount() {
        const cookies = new Cookies();
        let value = cookies.get('userid-planb');
        let path = this.props.location.search;
        let url = path.replace('?url=', '');
        let params = this.getUrlVars(url)
        let defaultCause = params["utm_term"]
        /*
         In the site address, if there is a utm_term parameter,
         its defaultCause value is, and if it does not exist, the defaultCause value is gf.
         */
        if (defaultCause != undefined && defaultCause != "") {
            this.setState({defaultCause: defaultCause});

        } else {
            defaultCause = "gf"
            this.setState({defaultCause: "gf"});

        }
        this.setCookie('defaultCause', defaultCause);

        let domain = this.extractHostname(url);
        if (domain == undefined || domain == "") {
            domain = "public"
        }
        this.setState({url: url, domain: domain});

        let DailyPoint;
        this.incrementDailyPoint();
        DailyPoint =  this.getCookie('dayilypoint');
        if (DailyPoint==="" || DailyPoint===undefined){
            DailyPoint=localStorage.getItem('dayilypoint');
        }
        this.setState({
            user: {
                dayilypoint: DailyPoint,
            }
        });

        this.isRegistred();
        var self = this;
        setInterval(function () {
            self.isCookieSet()
            ;
        }, 2000);
        let token =  this.getCookie('token');
        let userid =  this.getCookie('userid');
        if (userid == undefined) {
            userid = 0
        }
        if (token != undefined && token != "") {
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + token;
        }
        //localhost:8080/v1/?utm_medium=social&utm_term=aspca&user_reader=606944a3c60e1f5c07ddf73105b9b8e3&user_site=google.com
        /*
         When an article is shared on social networks, it is published in the form of the following link.
         https://goodbuzz.org/2020/09/27/bad-bunny-and-crocs-collab/?q-aspca-4dde692c3e56f04c1ce577069c6445c4
         If there is "?q-" value in the url field, it means that the user has entered the publisher
         site through social networks and the relevant process must be completed.
         */
        if (url!=="" && url!==undefined&& url.indexOf("?q")!==-1) {
            // In this section, if there is a cause after the -q string,
            // we will consider it defaultCause.
            const query =url.split("?q-")
            const parameters=query[1].split('-')
            const sharedCaues=parameters[0]
            this.setCookie('defaultCause', sharedCaues);
            this.setState({
               defaultCause:sharedCaues
           })
            const usersSharedId=parameters[1]
            /*
            Now using the userid and sharedCaues values ​​obtained in the previous steps we can create
            the urlVisited parameter and then send the GET request for the extended API.
            Relevant points will be calculated and stored in the relevant API.
             */
            const urlVisited = "/?user_reader=" + userid + "&user_site="
                + domain+"&utm_term="+sharedCaues+"&utm_medium=social&utm_source=share&user_shared="+usersSharedId
            axios.get(urlVisited).then(reponse => {

            }).catch(error => {
            });
            return;
        }else{
            /*
              If the user has not entered the relevant content on the publisher site from social media pages and is
              normally reading the content, the scenario of a normal reader will go through and different points should
              be considered.
             */
            const urlVisited = "/?user_reader=" + userid + "&user_site=" + domain
            axios.get(urlVisited).then(reponse => {


            }).catch(error => {
            });
        }
    }
    registerButtonClicked = () => {
        /*
              const CurentState=this.state.register
              this.setState({register:!CurentState})
        */
        let domain = this.state.domain
        if (domain == undefined || domain == "") {
            domain = "public-register"
        }
        window.open("/register/?domain=" + domain, 'register', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500px,height=800px');
        //return false;

    };

    extractHostname(url) {
        var hostname, domain;
        //find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }

        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];
        domain = hostname.replace("www.", "")
        domain = domain.replace(".com", "")
        domain = domain.replace(".net", "")
        domain = domain.replace(".org", "")

        return domain;
    }

    //https://www.w3schools.com/howto/img_avatar.png
    render() {
        let dropdown = "";
        let topbarContent = "";
        let registerBtn = "";
        let loadingBar = "";
        if (this.state.loading) {
            loadingBar = <Spinner className animation="grow" variant="success"/>;
            topbarContent = <div>
                <Spinner animation="grow" variant="success"/>
            </div>;
        }
        if (this.state.btnRegister) {
            let urlLogo="https://dev.givingforward.org/logo.png"
            if (this.state.defaultCause!="gf"){
                 urlLogo="https://dev.givingforward.org/logo/"+this.state.defaultCause+".svg"
            }

            topbarContent = <div>
                <Row md='12' className="d-none d-md-block">
                    Register to vote for your favorite cause. Current cause =
                    <img
                        src={urlLogo} alt={this.state.defaultCause} className={CLasses.avatar}>

                    </img>
                     <a style={{textDecoration: "underline"}}
                                                     href="https://www.givingforward.org/about-giving-forward/donations-how-it-works/" target="_blank">DETAILS</a>
                </Row>
                <Row md='4' className="d-block d-md-none">
                    Register to vote for your favorite cause.<a style={{textDecoration: "underline"}}
                                                                                   href="https://www.givingforward.org/about-giving-forward/donations-how-it-works/" target="_blank">DETAILS</a>

                </Row>
            </div>;

            registerBtn = <div>
                <Row md='12' className="d-none d-md-block">
                    <Nav.Link href="#"><Button
                        variant="primary"
                        className={Classes.btnRegister}
                        onClick={this.registerButtonClicked}>Register/Login</Button></Nav.Link>
                </Row>
                <Row md='4' className="d-block d-md-none">
                    <Nav.Link href="#"><Button
                        className={Classes.btnRegister}
                        variant="primary"
                        onClick={this.registerButtonClicked}>Register/Login</Button></Nav.Link>
                </Row>
            </div>;
        }
        if (this.state.register) {
            topbarContent = <UserData
                dayilypoint={this.state.user.dayilypoint}
                cause={this.state.user.cause}
            />;
            dropdown = <AvatarDropdown
                avatar={this.state.user.avatar}
                name={this.state.user.name}
                dayilypoint={this.state.user.dayilypoint}
                readerpoint={this.state.user.readerpoint}
                cause={this.state.user.cause}
            ></AvatarDropdown>;
        }

        let topbar =
            <div>
                <Row md='12' className="d-none d-md-block">
                    <Navbar style={{backgroundColor: "#ccccff"}} variant="dark">
                        <Navbar.Brand href="#home" className={Classes.logoM}
                                      style={{color: "black", paddingTop: "10px"}}>
                            <img
                                alt=""
                                src={LogoTop}
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            />{' '}
                            Givingforward

                        </Navbar.Brand>
                        <Nav className="mr-auto">
                            <Nav.Link href="#home">
                            </Nav.Link>
                            <Nav.Link style={{color: "black", paddingTop: "15px"}}
                                      href="#features">{topbarContent}</Nav.Link>

                        </Nav>
                        {registerBtn}
                        {loadingBar}
                        {dropdown}
                    </Navbar>

                </Row>

                <Row md='4' className="d-block d-md-none"><Navbar style={{backgroundColor: "#ccccff"}} variant="dark">
                    <Nav className="mr-auto" >
                        <Nav.Link href="#home" style={{paddingTop:"0px",marginRight:"10px"}}  >
                            <div>
                                <img
                                    alt="logo"
                                    src={LogoTop}
                                    width="20"
                                    height="20"
                                    className="d-inline-block align-top"
                                />{' '}
                            </div>

                        </Nav.Link>
                        <Nav.Link style={{color: "black",padding:"0px"}}
                                  href="#features">
                            {topbarContent}</Nav.Link>

                    </Nav>
                    {registerBtn}
                    {loadingBar}
                    {dropdown}
                </Navbar></Row>

                <br/>

            </div>;

        return (
            <Aux>
                {topbar}
            </Aux>
        );
    }
}
export default Topbar;