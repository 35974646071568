import React, {Component} from "react";
import Classes from './internalerror.module.css';

class Internalerror extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (<div className={Classes.bodyclass}>


            <div className={Classes.signwood}></div>
            <div className={Classes.signtext}>
                <p>503<br/>We have problem! not yours :)</p>
            </div>
            <div className={Classes.pin}></div>

            <div className={Classes.line}></div>
        </div>);
    }

}

export default Internalerror;