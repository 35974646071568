import React, {useState,useEffect} from "react";
import {Button, Form} from 'react-bootstrap';
import Classes from '../Register/DefaultRegister.module.css';
import {Dropdown} from "semantic-ui-react";
import Cookies from "universal-cookie";
import MultiSelect from "../Multi-Select/featured-selected";

const RegisterForm= (props)=> {
    const [defaultCause,setDefaultCause]= useState('gf')
    const [causleList,setcauseList]=useState([])
    useEffect(()=>{

        let defaultCauseCookie = getCookie('defaultCause');
        if (defaultCauseCookie){
            setDefaultCause(defaultCauseCookie)
        }
    },[])

    const getCookie=(key)=>{
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    useEffect(()=>{
        setcauseList(props.causeList)
    },[props.causeList])
    const causeChangeHandler=(key)=>{
        console.log(key)
        props.causeChanged(key)
    }
    return (
        <div className={Classes.centerDiv}>
            <Form.Group controlId="formBasicEmail">
                <Form.Control type="text" placeholder="Enter your name." onChange={props.nameChanged}/>
                <Form.Text className="text-muted">
                    Register so that we can count your points toward your cause.
                </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" required placeholder="Enter email" onChange={props.emailChanged}/>
                <Form.Text className="text-muted">
                    We'll al send you the occasional email.<a style={{textDecoration: "underline"}} href="https://www.givingforward.org/privacy/" target="_blank"> Privacy Policy.</a>
                </Form.Text>
            </Form.Group>
            <MultiSelect causeChanged={causeChangeHandler} causeList={causleList} defaultCause={props.defaultValue} feautredCauseList={props.feautredCauseList} ></MultiSelect>

            <br></br>
            <div className={Classes.centerDiv}>
                <Button className={Classes.buttonWidth}
                        onClick={props.registerHandler}
                        variant="success" type="submit">
                    Register
                </Button>
            </div>

        </div>);


}

export default RegisterForm;