
import React, {useState} from 'react'

import { Button, Card, Image,Modal,Header,Icon } from 'semantic-ui-react'
import Class from '../CauseCard/cards.module.css'
const CauseCards =React.memo(props => {
    const [open, setOpen] = React.useState(false)
    const [featuredCauseHover,setFeaturedCauseHover] = useState({
        title:"",
        description:"",
        logo:""

    });
    const hoverHandler=(title,logo,ds)=>{
        setFeaturedCauseHover({
            title:title,
            description:ds,
            logo:logo
        })
        setOpen(true)
    }
    return(
        <div className={Class.box}>
            <Card.Group  itemsPerRow={2}  >
                {props.feautredCauses.map((cause, index) => (
                    <Card key={cause.key} >
                        <Card.Content>
                            <Image
                                floated='right'
                                size='mini'
                                src={cause.logo_url}
                            />
                            <Card.Header style={{fontSize:"medium"}}>{cause.text}</Card.Header>
                            <Card.Description>
                          <div onClick={()=>hoverHandler(cause.text,cause.logo_url,cause.description)} style={{cursor:"pointer"}}>
                              { ((cause.description).length > 30) ?
                                  (((cause.description).substring(0,30-3)) + '...') :
                                  cause.description }
                          </div>

                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <div className='ui two buttons'>
                                <Button basic color='green' onClick={()=> props.selectCause(cause.value)}>
                                    Select
                                </Button>
                            </div>
                        </Card.Content>
                    </Card>

                ))}
            </Card.Group>
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}

            >
                <Modal.Header>{featuredCauseHover.title}</Modal.Header>
                <Modal.Content image>
                    <Image size='medium' src={featuredCauseHover.logo} wrapped />
                    <Modal.Description>
                        <p>{featuredCauseHover.description}</p>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpen(false)} positive>
                        Ok
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
        )
});

export default CauseCards
