import React, {Component} from "react";
import Classes from "../email.module.css";
import {Button, Form, Header, Image, Modal} from "semantic-ui-react";
import email from "../../../assets/images/email.jpg";

class EmailForm extends Component {
    openModal=()=>{
        this.setState({
            setOpen:true,
            open:true
        })
    }
    cloaseModal=()=>{
        this.setState({
            setOpen:false,
            open:false
        })
    }

    state = {
        setOpen:false,
        open:false,
        loading:true,
    }
    render() {
        let modal=   <Modal
            onClose={this.cloaseModal}
            onOpen={this.openModal}
            open={this.state.open}
            trigger={<Button inverted color='blue'>See template</Button>}
        >
            <Modal.Header>Email template sent</Modal.Header>
            <Modal.Content image>
                <Image size='large' src={email} wrapped />
                <Modal.Description>
                    <Header>Default values</Header>
                    <p>
                        Title : Please verify your Email.
                        <br></br>
                        Header : Givingforward
                        <br></br>
                        Text : Thanks for joining Giving Forward which powers GoodBuzz and other sites.Click the button to verify your email address:
                        <br></br>
                        Button : Verify
                        <br></br>
                        UrlHint : Or if the button is not showing click or paste this link into your browser.
                        <br></br>
                        CopyWrite : Givingforward - 2020-2021
                        <br></br>
                        Regards : Regards.
                        <br></br>
                        Footer : You and your friends generate points which convert into donations for your selected nonprofit when you visit Good Buzz. So, don't forget to share the posts you like.

                    </p>

                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Ok"
                    labelPosition='right'
                    icon='checkmark'
                    onClick={() =>
                        this.setState({
                            setOpen:false,
                            open:false
                        })
                    }
                    positive
                />
            </Modal.Actions>
        </Modal>;
        return(
            <div className={Classes.center}>
                <h3 style={{textAlign:"center"}}>Email parts</h3>
                <Form>
                    <Form.Field>
                        <label>Header</label>
                        <input placeholder='Givingforward' onChange={this.props.headerChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Title</label>
                        <input placeholder='Please verify your Email.' onChange={this.props.titleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Text</label>
                        <input placeholder='Thanks for joining Giving Forward ...' onChange={this.props.textChange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Button</label>
                        <input placeholder='Verify' onChange={this.props.buttonChange}  />
                    </Form.Field>  <Form.Field>
                    <label>Regards</label>
                    <input placeholder='Regards.' onChange={this.props.regardsChange}/>
                </Form.Field>
                    <Form.Field>
                        <label>Url Hint</label>
                        <input placeholder='Or if the button is not showing click or paste this link into your browser.' onChange={this.props.url_hintChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Footer</label>
                        <input placeholder='You and your friends generate points which ...' onChange={this.props.footerChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>Copy Write</label>
                        <input placeholder='Givingforward<br>2020-2021' onChange={this.props.copy_writeChange} />
                    </Form.Field>
                    <Button type='submit'  inverted color='green' onClick={this.props.sendRequest}>Submit</Button>
                    {modal}
                </Form>
            </div>
        );
    }

}
export default EmailForm;