import React, { useEffect, useState } from 'react';
import Cookies from "universal-cookie";
import axios from "../../axios-agent";
import Class from "./profile.module.css";
import {Spinner} from "react-bootstrap";
import {Button, Header, Table} from "semantic-ui-react";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
const Userprofile = (props) => {

    const [selectedFiles,setSelectedFiles] = useState(null);
    const [message,setMessage]=useState("")
    const [uploading,setUploading] = useState(false)
    const [disable,setDisable]=useState(false)
    const [avatarChanged,setChangeAvatar] = useState(false)
    const [errorMessage,setErrorMessage]=useState("")
    const [isLoggedIn,SetIsLoggedIn] = useState(false)
    const [causeKey,setCauseKey]=useState("")
    const [userid,setUserId] = useState("")
    const [causeList,setCauseList] =useState([{
      "value": "Alzheimer's Association",
      "text": "Alzheimer's Association",
      "key": "alz",
      "logo_url": "",
      "image": { avatar: true, src: "https://dev.givingforward.org/logo.png" }
  },
  ])
    const [loading,setLoading] =useState(true)
    const [content,setContent] =useState(<div className={Class.center} key={"loading"} >
        <hr></hr>
        <h6>Please wait...</h6>
        <Spinner className animation="border" variant="success"/>
    </div>)
    const [cause,setCause] = useState('')
    const [first_name,setFirst_name] = useState('')
    const [last_name,setLast_name] = useState('')
    const [avatar,setAvatar]=useState('')
    const [email,setEmail] = useState('')
    const [reader_point,setReader_point] = useState(0)
    const [referral_point,setReferral_point] = useState(0)
    const [author_point,setAuthor_point] = useState(0)
    const [logo,setLogo] = useState('')

    //......................................................................
    const setCookie=(key,value)=>{
        const cookies = new Cookies();
        cookies.set(key, value, {path: '/',secure:true,sameSite:'none'});
        localStorage.setItem(key, value);
    }
    //......................................................................
    const inputImageChangeHandler=(event)=> {
        const userId=localStorage.getItem('userid');
        setErrorMessage("")
        setMessage("")
        const choosedFile=event.target.files[0]
        const fileName = choosedFile.name
        console.log(fileName)
        if (choosedFile!=undefined){
            setUploading(true)
            let form_data = new FormData();
            form_data.append('avatar',choosedFile );
            axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('token')
            axios.post("/user/"+userId+"/avatar", form_data, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
                .then(res => {
                    const fileNameSaved=res.data.name
                    console.log(res)
                    setChangeAvatar(true)
                    setMessage("Upload successful")
                    setUploading(false)

                    const newAvatar="https://t.givingforward.org/v1/user/avatar/"+fileNameSaved
                    setAvatar(newAvatar)
                    setCookie('avatar',newAvatar);
                    ///https://t.givingforward.org/v1/user/4dde692c3e56f04c1ce577069c6445c4/avatar.jpg
                })
                .catch(err => {
                    setUploading(false)
                    setErrorMessage("Upload failed")
                    console.log(err)}
                )
        }
    }
    //......................................................................
   const removeCharAt= (input,i) =>{
        var tmp = input.split(''); // convert to an array
        tmp.splice(i - 1 , 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
        return tmp.join(''); // reconstruct the string
    }
    const logout=()=>{
        let domainName=window.location.hostname
        domainName=removeCharAt(domainName)
        const cookies = new Cookies();
        cookies.remove("userid", {path: '/',secure:true,sameSite:'none'})
        cookies.remove("token", {path: '/',secure:true,sameSite:'none'})
        cookies.remove("userid", {domain: domainName,path: '/'})
        cookies.remove("token", {domain: domainName,path: '/'})
        localStorage.removeItem("userid");
        localStorage.removeItem("token");
        localStorage.removeItem("cause");

        let domain= getHostedSite()+'c/?clear=true'
        window.location.replace(domain)
        //window.close()
    }
    const getCookie=(key)=>{
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){
            value=cookies.get(key+'-planb');
        }
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    const jsUcfirst=(string)=> {

        return string.charAt(0).toUpperCase() + string.slice(1);

    }
    const goBack=()=> {props.history.goBack();}
    const  goChaneCause=()=>{props.history.push("/cause");}
    const getHostedSite=()=>{
        let domain=window.location.hostname;
        domain= domain.replace('i.','')
        return "https://"+domain+"/"
    }

    //....................................................................................
    useEffect(()=>{
        let causeListTemp =[]
        const userid=getCookie("userid")
        const token=getCookie("token")
        if (userid===undefined|| token===undefined){
            props.history.push("/register");
        }
        var auth = {
            userid: userid,
            token: token
        };
        axios.get('/cause').then(response => {
            setCauseList(response.data)
            causeListTemp= response.data
        }).catch(error => {
            console.log(error);
            props.history.push("/error");
        });
        axios.post('/auth', auth).then(response => {
            const urlLogo="https://dev.givingforward.org/logo/"+response.data.cause+".svg"
            let name = "Dear friend";
            let cause=""
            if (response.data.display_name !== "") {
                const names = response.data.display_name.split(' ');
                name = names[0];
                name=jsUcfirst(name);
            }
            causeListTemp.map(c=>{
                if (c.key===response.data.cause){
                    cause=c.value
                }
            });
            console.log(response.data)
            setFirst_name(name)
            setLast_name(response.data.last_name)
            setAvatar(response.data.avatar)
            setEmail(response.data.email)
            setReader_point(response.data.reader_point)
            setReferral_point(response.data.referral_point)
            setCause(cause)
            setAuthor_point(response.data.author_point)
            setLogo(urlLogo)
            setLoading(false)

        }).catch(error => {
            props.history.push("/register");
        })
    },[])

    useEffect(()=>{
        let authorPointCell="";
        if (author_point!=0){
            authorPointCell= <Table.Row>
                <Table.Cell>
                    <Header as='h4' >
                        <Header.Content>
                            Author Points
                            <Header.Subheader>You get six points every time an article you write on our platform is read.</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Table.Cell>
                <Table.Cell>{author_point}</Table.Cell>
            </Table.Row>
        }
        if (loading){
            setContent(<div className={Class.center} key={"loading"} >
                <hr></hr>
                <h6>Please wait...</h6>
                <Spinner className animation="border" variant="success"/>
            </div>)

        }else {
            setContent(
                    <div className={Class.center}>
                        <div className={Class.center}>
                            <img
                                src={avatar}
                                name="aboutme" width="140" height="140" border="0" style={{borderRadius:"50%"}}>
                            </img>

                            <h3 className="media-heading">Hi {first_name}</h3>
                            <span><strong>Email: {email} </strong></span>
                            <br></br>

                            <span><strong>Cause: </strong></span>
                            <span className="label label-warning">{cause}</span>
                            <br></br>
                            <img
                                src={logo} alt={cause} className={Class.causeLogo}>

                            </img>
                            <br></br>

                        </div>
                        <hr></hr>
                        <div className={Class.center}>
                            <Table basic='very' celled collapsing >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Type of points</Table.HeaderCell>
                                        <Table.HeaderCell>Amount of points</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' >
                                                <Header.Content>
                                                    Reader Points
                                                    <Header.Subheader>You get six points each time you read or watch content on a Giving Forward site.</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell>{reader_point}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as='h4' >
                                            <Header.Content>
                                                Referral Points
                                                <Header.Subheader>You get six points every time something you share on social media is read via your share on our platform.</Header.Subheader>
                                            </Header.Content>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{referral_point}</Table.Cell>
                                </Table.Row>
                                {authorPointCell}
                            </Table>
                            <br></br>
                            <div className="ui buttons">
                                <button className="ui red basic button" onClick={() => {
                                    window.close()
                                }}>Close</button>
                                <button className="ui blue basic button" onClick={goChaneCause}>Change Cause</button>
                                <Button  type="button" className="ui green basic button" htmlFor="file"  as="label">Change Avatar</Button>
                                <input type="file" id="file"
                                       accept="image/jpeg"
                                       hidden style={{ display: "hidden" }}
                                       onChange={inputImageChangeHandler} />
                            </div>
                                <FormHelperText style={{color:"green",textAlign:'center',fontSize:"14"}}>
                                    <b> {message}</b>
                                </FormHelperText>
                                <FormHelperText error>
                                    {errorMessage}
                                </FormHelperText>
                            </div>
                        {uploading? <LinearProgress />:null}

                            <br></br>

                        <div className="modal-footer">
                            <div className={Class.center}>
                                <Button basic color='red' fluid onClick={logout}>
                                    Logout
                                </Button>
                            </div>
                        </div>
                    </div>

            )
        }
    },[loading,message,errorMessage,uploading,avatar])

    return(<div>
            {content}
        </div>)
}
export default Userprofile;