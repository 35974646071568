import CLasses from "../topbar.module.css";
import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {ButtonGroup, Dropdown} from 'react-bootstrap';
import Aux from '../../../hoc/Auxilary/Auxilary';
import {Link, withRouter} from "react-router-dom";
import Cookies from "universal-cookie";

const AvatarDropdown= (props)=>{
    let openProfile=()=>{

    }
    const [avatar,setAvatar] = useState('')
    useEffect(()=>{
        setAvatar(props.avatar)
    },[])
    useEffect(()=>{
        const avatarUrl=getCookie('avatar')
        if (avatarUrl!=='' && avatarUrl!==undefined){
            setAvatar(avatarUrl)
        }
    })
    const getCookie=(key)=>{
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){

            value=cookies.get(key+'-planb');
            console.log('planb: ',value)
        }
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    return(
        <Aux>
            <Dropdown drop='left' as={ButtonGroup}>
                <a style={{cursor:"pointer"}} onClick={()=>{
                    window.open("/profile", 'profile', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500px,height=800px');
                }} >
                    <img
                        src={avatar} alt="Avatar" className={CLasses.avatar}>

                    </img>
                </a>
{/*
                <Dropdown.Toggle split variant="default" id="dropdown-split-basic" />
*/}

                <Dropdown.Menu>
                    {/*       <Dropdown.Item href="#">Hi {props.name}!</Dropdown.Item>
                    <hr></hr>
                    <Dropdown.Item href="#">Cause: {props.cause}</Dropdown.Item>
                    <Dropdown.Item href="#">Total point: {props.readerpoint} </Dropdown.Item>
                    <Dropdown.Item href="#">Daily point: {props.dayilypoint}</Dropdown.Item>*/}
                </Dropdown.Menu>
            </Dropdown>
        </Aux>

    );
};
export default  withRouter(AvatarDropdown);