import React,{useState,useEffect,useCallback,useReducer} from 'react';
import MultiSelect from "../../Components/Multi-Select/featured-selected";
import Cookies from "universal-cookie";
import CLasses from "../register/register.module.css";
import axios from "../../axios-agent";
import {Button, Spinner} from "react-bootstrap";
import SmallWindowBox from "../../Components/ui/SmallWindowBox/SmallWindowBox";
import SocialButton from "../../Components/Social/social/SocialButton";
import AlertDialog from "../../Components/ui/alert/alert";
import RegisterForm from "../../Components/Register/registerForm";
import crypto from "crypto-js";
const FeaturedRegister=(props=> {
    const [cause,setCause] = useState('gf')
    const [causeValue,setCauseValue]=useState('General Fund')
    const [loadingCause,setLoadingCause]=useState(true)
    const [defaultCauseCell,setDefaultCauseCell] = useState({
        value: "General Fund",
        text: <span><img src="https://dev.givingforward.org/logo.png" className={CLasses.logoCause}/> General Fund</span>,
        key: "gf",
    })
    const [socialRegisterFinished,setSocialRegisterFinished] = useState(false)
    const [appid,SetAppId]=useState("369371820691717")
    const [success,setSuccess] = useState(false)
    const [showAlertState,setShowAlert] = useState(false)
    const [loading,setLoading] = useState(false)
    const [firstname,setFirstname] = useState("")
    const [lastname,setLastName] = useState("")

    const [registered,setRegistered] = useState(false)
    const [name,setName] = useState("")
    const [email,setEmailAddress] = useState("")
    const [avatar,setAvatar] = useState("")

    const [hash,setHash] =  useState("")
    const [site,setSite] =  useState("")
    const [social,setSocial] =  useState(false)
    const [readerpoint,setReaderPoint]=useState(0)
    const [dayilypoint,setdDyilypoint]=useState(0)

    const [LoadingContent,setLoadingContent]=useState("");
    const [login,setLogin] = useState(false)
    const [currentProvider,setCurrentProvider]=useState('')
    const [causeList,setCauselist] = useState([])
    const [feautredCauseList,setFeautredCauseList] = useState([])
    const [dicCuase,setDictCause]=useState({});
    let nodes = {}
    const [alertMessage,setAlertMessage]=useState({
        title: "",
        typeClass: "success",
        message: ""
    })

    let tempCauseList = [{
        "value": "General fund",
        "text": "General fund",
        "key": "alz",
        "logo_url": "",
        "image": { avatar: true, src: "https://dev.givingforward.org/logo.png" },
        "description":"",
        "hero_image":""
    },
    ];
    const setCookiesForAuth=(hash, token)=> {
        setCookie('userid', hash)
        setCookie('token', token)
    }
    const onLoginSuccess=(user)=>{
        console.log(user._profile.firstName)


        let domain =window.location.hostname
        if (user == undefined) {
            showAlertHandler("Error", "Internal server error, please try again later.", "danger");
        } else {
            setFirstname(user._profile.firstName)

            setFirstname(user._profile.firstName)
            setLastName(user._profile.lastName)
            setEmailAddress(user._profile.email)
            setAvatar(user._profile.profilePicURL)
            setFirstname(user._profile.firstName)
            setLastName(user._profile.lastName)
            setEmailAddress(user._profile.email)
            setName( user._profile.name)
            setHash(crypto.MD5(email).toString())
            setSocial(true)
            setLoading(false);
           const userTemp = {
                firstname: user._profile.firstName,
                lastname: user._profile.lastName,
                avatar: user._profile.profilePicURL,
                name: user._profile.name,
                email: user._profile.email,
                hash: crypto.MD5(user._profile.email).toString(),
                cause: cause,
                social: true,
                site:window.location.hostname
            };
            console.log(user)
            axios.post('/tb/register', userTemp).then(response => {
                console.log(response)
                setLoading(false)
                if (response.status) {
                    setCookiesForAuth(userTemp.hash, response.data.token);
                }
                showAlertHandler("Success", "Registration was successful", "success");
                setSuccess(true)
                //.....................................
                var tags = [
                    domain,
                    response.data.cause,
                    "reader"
                ];
                const mailchimp = {
                    firstname: response.data.firstname,
                    lastname: response.data.lastname,
                    email:  response.data.email,
                    tags: tags,
                    status: "subscribed"
                };
                axios.post('/mailchimp', mailchimp).then(response => {
                    console.log(response);
                }).catch(error => {
                    console.log(error)
                });
                //.....................................
                if (!response.data.registered) {
                    if (domain===""){
                        domain="public"
                    }

                    props.history.push("/cause");
                } else {
                    setLoading(false)
                    let domain= getHostedSite()+'c/?userid='+crypto.MD5(user._profile.email).toString()+'&token='+response.data.token
                    window.location.replace(domain)
                    //window.close()
                }
                setLogin(true)
                setCurrentProvider(user._provider)
            }).catch(error => {
                    console.log(error);
                    if (error.status) {
                        showAlertHandler("Error", error.response.data.message, "danger");

                    } else {
                        showAlertHandler("Error", "Internal server error, please try again later.", "danger");

                    }
                    setLoading(false);
                });
            setLogin(true)
            setCurrentProvider(user._provider)
        }
    };

    const registerDefaultHandler = () => {
        console.log(email)
        if (email == "") {
            showAlertHandler("Error", "email could not empty.", "danger");
            setLoading(false)
            return;
        }
        if (!validateEmail(email)) {
            showAlertHandler("Error", "Email is not valid.", "danger");
            return;
        }
        setLoading(true)
        if (avatar == "") {
            setAvatar('https://www.dev.givingforward.org/avatar.png')

        }
        if (cause == "" || cause == undefined) {
            const cookies = new Cookies();
            let defaultCause = getCookie('defaultCause');
            if (defaultCause===undefined ){
                defaultCause=localStorage.getItem('defaultCause');

            }
            if (defaultCause == undefined) {
                defaultCause = "gf"
            }
            setCause(defaultCause)
        }
        setHash(crypto.MD5(email).toString())
        const user={
            avatar: avatar,
            name: name,
            email: email,
            hash: crypto.MD5(email).toString(),
            cause: cause,
            social: social,
            site:window.location.hostname
        }
        console.log(user)
        axios.post('/tb/register', user).then(response => {
            setEmailAddress("")
            console.log(response);
            setLoading(false)
            setRegistered(true)
            showAlertHandler("Success", response.data.message, "success");


        })
            .catch(error => {
                console.log(error);
                if (error.status) {
                    showAlertHandler("Error", error.response.data.message, "danger");

                } else {
                    showAlertHandler("Error", "Internal server error, please try again later.", "danger");

                }
                setLoading(false)
                console.log(loading)
            });
    };


    useEffect(()=>{
        console.log("component did mount");

        let domainName=window.location.hostname;
        console.log("domain")
        switch (domainName) {
            case "i.lennyletter.com":
                SetAppId("320777833011095")
                break;
            case "goodbuzz.org":
                SetAppId("369371820691717")

                break;
            default:
                SetAppId("369371820691717")
                break;
        }
        console.log(domainName)
        let user= {}
        let defaultCause = getCookie('defaultCause');
        if (defaultCause){
            console.log(defaultCause)
            setCause(defaultCause)
        }else {
            setCause('gf')
        }

        //...............................................................
        // let path =this.props.location.search;
        // console.log(path);
        // let domain = path.replace("?domain=", "");
        axios.get('/cause').then(response => {
            tempCauseList = response.data;
            setLoading(false)

            tempCauseList.map(cauesTemp=>{
                let tmpCause= {
                    key: cauesTemp.key,
                    text: cauesTemp.value,
                    value: cauesTemp.value,
                    logo_url:cauesTemp.logo_url,
                    image: {  avatar: true, src: cauesTemp.image }

                };
                dicCuase[cauesTemp.value]=cauesTemp.key
                setDictCause(dicCuase)
                causeList.push(tmpCause)
                setCauselist(causeList)
                const defaultCause = getCookie("defaultCause")

                if (cauesTemp.key===defaultCause){
                    const selectedCell={
                        value: cauesTemp.value,
                        text: <span><img src={cauesTemp.image} className={CLasses.logoCause}/>{cauesTemp.value}</span>,
                        key: cauesTemp.key,
                    }
                    setDefaultCauseCell(selectedCell)
                }
            })
            setLoadingCause(false)
        }).catch(error => {
            setLoadingCause(false)

        });
        axios.get('/cause/type/3').then(response => {
            tempCauseList = response.data;
            console.log(tempCauseList)
            tempCauseList.map(cauesTemp=>{
                let tmpCause= {
                    key: cauesTemp.key,
                    text: <span><img src={cauesTemp.image} className={CLasses.logoCause}/> {cauesTemp.text}</span>,
                    value: cauesTemp.value,
                    description:cauesTemp.description

                };

                feautredCauseList.push(tmpCause)
                setFeautredCauseList(feautredCauseList)
                if (cauesTemp.key===cause){
                    const selectedCell={
                        value: cauesTemp.value,
                        text: <span><img src={cauesTemp.image} className={CLasses.logoCause}/>{cauesTemp.value}</span>,
                        key: cauesTemp.key,
                    }
                    setDefaultCauseCell(selectedCell)
                }
            })
            setLoadingCause(false)
        }).catch(error => {
            setLoadingCause(false)

        });
    },[]);
    //...............................................................................
    const setNodeRef=(provider, node)=> {
        if (node) {
            nodes[provider] = node
        }
    }
    const onLoginFailure=(err) =>{
        console.error(err);
        setCurrentProvider('')
    }

   const onLogoutSuccess= () =>{
        setLogin(false)
       setCurrentProvider('')
    }
    const onLogoutFailure=(err) =>{
        console.error(err)
    }
    const  logout=()=> {
        if (login && currentProvider) {
            nodes[currentProvider].props.triggerLogout()
        }
    }
    const getCauseKey=() =>{
        let causeNew=causeValue
        causeList.map(cause => {
            if (cause.value.includes(causeNew)) {
                setCause(cause.key)
                console.log(cause)
            }
        });
    }
    const getHostedSite=()=>{
        let domain=window.location.hostname;
        domain= domain.replace('i.','')
        return "https://"+domain+"/"
    }
    const validateEmail=(email) =>{
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
   const registerNameChanged = (event) => {
        console.log("registerNameChanged")
       setShowAlert(false)
        setName(event.target.value)
    };
    const registerEmailChanged = (event) => {
        console.log(email)
        console.log(cause)

        const value=event.target.value
        setEmailAddress(value)

        setShowAlert(false)
    };
    const registerCauseChanged = (key) => {
        console.log(key)
        if (!key){
            key='General Fund'
        }
        setCause(key)
    };
    const showAlertHandler=(title, message, type)=> {
        setAlertMessage({
            message:message,
            title:title,
            type:type
        })
        setShowAlert(true);
    }
   const btnAlertClicked = () => {
        console.log("hide alert")
        setShowAlert(false)
    };
    const getCookie=(key)=>{
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    const removeCharAt= (input,i) =>{
        var tmp = input.split(''); // convert to an array
        tmp.splice(i - 1 , 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
        return tmp.join(''); // reconstruct the string
    }
    const setCookie=(key,value)=>{
        const cookies = new Cookies();
        cookies.set(key, value, {path: '/',secure:true,sameSite:'none'});
        localStorage.setItem(key, value);
    }



    //...............................................................................
    const  [Content,setContent]=useState(<div className={CLasses.centerDiv}>
        <hr></hr>
        <h6>Please wait...</h6>
        <Spinner className animation="border" variant="success"/>
    </div>)

    useEffect(()=>{
        if (!loading){
            setLoadingContent("")
        }else {
            setLoadingContent(<div className={CLasses.centerDiv}>
                <hr></hr>
                <h6>Please wait...</h6>
                <Spinner className animation="border" variant="success"/>
            </div>)
        }
    },[loading])
    useEffect(()=>{
        if (loadingCause) {
           setContent( <div className={CLasses.centerDiv}>
               <hr></hr>
               <h6>Please wait...</h6>
               <Spinner className animation="border" variant="success"/>
           </div>)
        }
    },[loadingCause])

    useEffect(()=>{
        if (registered){
           setContent( <div>
               <SmallWindowBox>
                   <h2 className={CLasses.centerDiv}>congratulations</h2>
                   <hr></hr>
                   <div className={CLasses.centerDiv}>
                       <p>
                           Please check your email. You will receive a message confirming your email address.
                       </p>
                   </div>
                   <Button onClick={() => {
                       window.close()
                   }} variant="success">Close</Button>
               </SmallWindowBox>
           </div>)
        }
    },[registered])

    useEffect(()=>{

        if (!login && !registered &&
            !success && !loadingCause) {
            console.log(alertMessage)
            setContent(<div>
                  <SmallWindowBox>
                      <h4 className={CLasses.centerDiv}>Register</h4>
                      <hr></hr>
                      <AlertDialog
                          show={showAlertState}
                          title={alertMessage.title}
                          hideFunc={btnAlertClicked}
                          typeClass={alertMessage.typeClass}
                          message={alertMessage.message}></AlertDialog>
                      <RegisterForm
                          nameChanged={registerNameChanged}
                          emailChanged={registerEmailChanged}
                          causeChanged={registerCauseChanged}
                          causeList={causeList}
                          defaultValue={defaultCauseCell.value}
                          feautredCauseList={feautredCauseList}
                          registerHandler={registerDefaultHandler}>

                      </RegisterForm>

                      {LoadingContent}
                      <hr className={CLasses.hrCustom}></hr>
                      <div>
                          <SocialButton
                              className={CLasses.buttonWidth}
                              provider='google'
                              type="google"
                              appId='580879083076-sfkb4hksl6soj3nbhlk8tpe4oi644mmf.apps.googleusercontent.com'
                              onLoginSuccess={onLoginSuccess}
                              onLoginFailure={onLoginFailure}
                              onLogoutSuccess={onLogoutSuccess}
                              onLogoutFailure={onLogoutFailure}
                              getInstance={setNodeRef.bind(this, 'google')}
                              key={'google'}
                          >
                              Register with Google
                          </SocialButton>
                          <br></br>
                          <SocialButton
                              className={CLasses.buttonWidth}
                              provider='facebook'
                              type="facebook"
                              appId={appid}
                              onLoginSuccess={onLoginSuccess}
                              onLoginFailure={onLoginFailure}
                              onLogoutSuccess={onLogoutSuccess}
                              getInstance={setNodeRef.bind(this, 'facebook')}
                              key={'facebook'}
                          >
                              Register with Facebook
                          </SocialButton>
                      </div>
                      <hr></hr>
                      {/*  <div className={CLasses.centerDiv} >
                            <img className={CLasses.logoButton} src={Logo}></img>

                        </div>*/}


                  </SmallWindowBox>

              </div>)
        }

    },[login,registered,success,loadingCause,showAlertState,email,cause,alertMessage,appid])

    //...............................................................................
    return(
        <React.Fragment>

            {Content}
        </React.Fragment>)
});
export default FeaturedRegister;