import React, {Component} from "react";
import Aux from '../../hoc/Auxilary/Auxilary';
import SmallWindowBox from "../../Components/ui/SmallWindowBox/SmallWindowBox";
import Classes from './SocialSharing.module.css';
import {Button, Spinner} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from "universal-cookie";
import axios from "../../axios-agent";
import {withRouter} from "react-router-dom";
import SocialButton from "../../Components/Social/social/SocialButton";
import crypto from "crypto-js";
import AlertDialog from '../../Components/ui/alert/alert';

class SocialSharing extends Component {

    alertMessage = {
        title: "",
        typeClass: "success",
        message: ""
    };
    userSite = {
        firstname: "",
        lastname: "",
        avatar: "",
        name: "",
        email: "",
        hash: "",
        cause: "",
        readerpoint: 0,
        dayilypoint: 0,
        social: false
    };
    state = {
        register: true,
        loading: true,
        noUrl: false,
        url: "",
        userid: "",
        cause: "",
        showAlert: false
    };

    constructor() {
        super();
        this.nodes = {};

        this.isRegistred = this.isRegistred.bind(this);
        this.onLoginSuccess = this.onLoginSuccess.bind(this);
        this.onLoginFailure = this.onLoginFailure.bind(this);
        this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
        this.onLogoutFailure = this.onLogoutFailure.bind(this);
        this.SkipRegister = this.SkipRegister.bind(this);
        this.redirect = this.redirect.bind(this);
        this.logout = this.logout.bind(this)


    }

    showAlert(title, message, type) {

        this.alertMessage.message = message;
        this.alertMessage.title = title;
        this.alertMessage.typeClass = type;
        this.setState({
            showAlert: true
        });
    }

    setNodeRef(provider, node) {
        if (node) {
            this.nodes[provider] = node
        }
    }

    btnAlertClicked = () => {
        this.setState({
            showAlert: false
        });
    };
    setCookie(key,value){
        const cookies = new Cookies();
        cookies.set(key, value, {path: '/',secure:true,sameSite:'none'});
        // let domainName=window.location.hostname
        // domainName=this.removeCharAt(domainName)
        // cookies.set(key, value, {domain: domainName,secure:true,sameSite:'none'});
        localStorage.setItem(key, value);
    }
    setCookiesForAuth(hash, token) {
        this.setCookie('userid', hash)
        this.setCookie('token', token)

    }

    addQuery=(url, param)=>{
        if (param === "" || param === undefined
            || url==="" || url===undefined) {
            return url
        }
        url=url+"-"+param
        return url;

    }
    redirect = () => {
        var url = this.state.url;
        console.log(url);
        if (url !== "" || url !== undefined) {
            let newUrl;
            var uri_dec = decodeURIComponent(url);
            var uriArray = uri_dec.split("&via=");
            let cause = this.state.cause;
            let userid = this.state.userid;
            //newUrl = this.addParam(uriArray[0], "utm_term", cause);
            //newUrl = this.addParam(newUrl, "user_shared", userid);
            newUrl=uriArray[0]
            if(newUrl.charAt(newUrl.length-1)!=="/"){
                newUrl=newUrl+"/"
            }
            newUrl=newUrl+"?q"
            newUrl=this.addQuery(newUrl,cause)
            newUrl=this.addQuery(newUrl,userid)
            if (uriArray.length > 1) {
                newUrl = newUrl + "&via=" + uriArray[1]
            }
            console.log(newUrl);
          //  newUrl=newUrl.replace("/&utm_term","/?utm_term")
            window.location.assign(newUrl)
        } else {
            window.close()
        }
    };
    SkipRegister = () => {

        this.setState({cause: "gf"}, () => {
            this.redirect()
        });

    };
    getCookie(key){
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    isRegistred = () => {
        this.setState({loading: true});

        var hashContent = "";
        var tokenContent = "";
        const cookies = new Cookies();
        hashContent =  this.getCookie('userid');
        tokenContent =  this.getCookie('token');
        var auth = {
            userid: hashContent,
            token: tokenContent
        };
        axios.post('/auth', auth).then(response => {
            this.setState({register: true, loading: false, cause: response.data.cause, userid: response.data.hash});
            this.redirect()


        }).catch(error => {
            this.setState({register: false, loading: false});
            console.log(error);

        });

    };

    getUrlVars(url) {
        var vars = {};
        url = decodeURI(url);
        var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
        return vars;
    }

    componentDidMount() {
        let path = this.props.location.search;
        const cookies = new Cookies();
        let url = path.replace('?url=', '');
        if (url === undefined || url === "") {
            this.setState({noUrl: true})
        } else {
            let params = this.getUrlVars(url);
            let defaultCause = params["utm_term"];
            this.setState({url: url});
            this.isRegistred()
        }

    }

    onLoginFailure(err) {
        console.error(err);

        this.setState({
            logged: false,
            currentProvider: '',
            user: {}
        });
    }

    onLogoutSuccess() {
        this.setState({
            logged: false,
            currentProvider: '',
            user: {}
        })
    }

    onLogoutFailure(err) {
        console.error(err)
    }

    logout() {
        const {logged, currentProvider} = this.state;

        if (logged && currentProvider) {
            this.nodes[currentProvider].props.triggerLogout()
        }
    }

    onLoginSuccess(user) {
        console.log("loging successfulle")
        this.setState({loading: true});
        this.userSite.avatar = user._profile.profilePicURL;
        this.userSite.firstname = user._profile.firstName;
        this.userSite.lastname = user._profile.lastName;
        this.userSite.email = user._profile.email;
        this.userSite.name = user._profile.name;
        this.userSite.hash = crypto.MD5(this.userSite.email).toString();
        this.userSite.social = true;
        axios.post('/tb/register', this.userSite).then(response => {

            if (response.status) {
                this.setCookiesForAuth(this.userSite.hash, response.data.token);
            }
            if (!response.data.registered) {
                let destUrl = "/cause?url=" + this.state.url;
                this.props.history.push(destUrl);
            } else {
                let userid = this.userSite.hash;
                let cause = response.data.cause;
                this.setState({
                    userid: userid,
                    cause: cause
                });
                this.redirect()
            }
        })
            .catch(error => {
                console.log(error);
                if (error.status) {
                    this.showAlert("Error", error.response.data.message, "danger");

                } else {
                    this.showAlert("Error", "Internal server error, please try again later.", "danger");

                }
                this.setState({loading: false});
                console.log(this.state.loading)
            });
        let domain = this.state.domain;
        var tags = [
            domain
        ];
        const mailchimp = {
            firstname: this.userSite.firstname,
            lastname: this.userSite.lastname,
            email: this.userSite.email,
            tags: tags,
            status: "subscribed"
        };
        axios.post('/mailchimp', mailchimp).then(response => {
            console.log(response);
        }).catch(error => {
            console.log(error)
        });
        this.setState({
            logged: true,
            currentProvider: user._provider,
            user
        })
    }

    render() {
        let content = "";
        if (this.state.noUrl) {
            content = <div className={Classes.centerDiv}>
                <h3>Please enter url.</h3>
            </div>;
        }
        if (this.state.loading) {
            content = <div className={Classes.centerDiv}>
                <h6>Please wait...</h6>
                <Spinner className animation="border" variant="success"/>
            </div>;
        }
        if (!this.state.register) {
            content = <div>
                <div>
                    <div className={Classes.centerDiv}>
                        <h5>You could join us and donate your cause!</h5>
                    </div>
                    <hr></hr>
                    <SocialButton
                        className={Classes.buttonWidth}
                        provider='google'
                        type="google"
                        appId='580879083076-sfkb4hksl6soj3nbhlk8tpe4oi644mmf.apps.googleusercontent.com'
                        onLoginSuccess={this.onLoginSuccess}
                        onLoginFailure={this.onLoginFailure}
                        onLogoutSuccess={this.onLogoutSuccess}
                        onLogoutFailure={this.onLogoutFailure}
                        getInstance={this.setNodeRef.bind(this, 'google')}
                        key={'google'}
                    >
                        Register with Google
                    </SocialButton>
                    <br></br>
                    <SocialButton
                        className={Classes.buttonWidth}
                        provider='facebook'
                        type="facebook"
                        appId="369371820691717"
                        onLoginSuccess={this.onLoginSuccess}
                        onLoginFailure={this.onLoginFailure}
                        onLogoutSuccess={this.onLogoutSuccess}
                        getInstance={this.setNodeRef.bind(this, 'facebook')}
                        key={'facebook'}
                    >
                        Register with Facebook
                    </SocialButton>
                    <hr></hr>
                    <div className={Classes.centerDiv}>
                        <Button className={Classes.buttonWidth}
                                onClick={this.SkipRegister}
                                variant="success" type="submit">
                            Or share with GivingForward.org's general fund.
                        </Button>
                    </div>
                </div>
            </div>
        }

        return (
            <Aux>
                <SmallWindowBox>
                    {content}
                    <AlertDialog
                        show={this.state.showAlert}
                        title={this.alertMessage.title}
                        hideFunc={this.btnAlertClicked}
                        typeClass={this.alertMessage.typeClass}
                        message={this.alertMessage.message}></AlertDialog>
                </SmallWindowBox>

            </Aux>);
    }
}

export default withRouter(SocialSharing);