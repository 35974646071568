import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Aux from '../../../hoc/Auxilary/Auxilary';
import {Button, Form, Spinner} from "react-bootstrap";
import Classes from "../../../Components/Register/DefaultRegister.module.css";
import {Dropdown} from "semantic-ui-react";
import CLasses from "../../register/register.module.css";
import SmallWindowBox from "../../../Components/ui/SmallWindowBox/SmallWindowBox";
import Cookies from "universal-cookie";
import axios from "../../../axios-agent";
import {withRouter} from "react-router-dom";
import AlertDialog from "../../../Components/ui/alert/alert";

class CauseChange extends Component {
    alertMessage = {
        title: "",
        typeClass: "success",
        message: ""
    };
    tempCauseList= [{
        "value": "",
        "text": "",
        "key": "",
        "logo_url": "",
    },
    ];
    causeList = [
    ];
    constructor(props) {
        super(props);
        this.state = {
            defaultCauseCell:{
                value: "General Fund",
                text: <span><img src="https://dev.givingforward.org/logo.png" className={CLasses.logoCause}/> General Fund</span>,
                key: "gf",
            },
            loadingApi: false,
            register: true,
            loading: true,
            showAlert: false,
            noUrl: true,
            userid: "",
            cause: "",
            url: ""
        };
        this.setCause = this.setCause.bind(this);
        this.isRegistred = this.isRegistred.bind(this);
        this.goBack=this.goBack.bind(this)

    }
    getCellOfDefaultCause(defaultCause){
        this.causeList.map(cause=>{
            if (defaultCause === cause.key ){
                console.log(cause.key)
                return cause;
            }
        })
        return null;
    }
    showAlert(title, message, type) {

        this.alertMessage.message = message;
        this.alertMessage.title = title;
        this.alertMessage.typeClass = type;
        this.setState({
            showAlert: true
        });
    }

    isCameFromSocilLogin() {

    }

    componentDidMount() {
        let path = this.props.location.search;
        const cookies = new Cookies();
        let url = path.replace('?url=', '');
        if (url === undefined || url === "") {
            this.setState({noUrl: true})
        } else {
            this.setState({noUrl: false});

            this.setState({url: url});
        }
        this.isRegistred();

        axios.get('/cause').then(response => {
            this.tempCauseList = response.data;
            this.tempCauseList.map(caues=>{
                let tmpCause= {
                    key: caues.key,
                    text: <span><img src={caues.image} className={CLasses.logoCause}/> {caues.text}</span>,
                    value: caues.value,
                };
                this.causeList.push(tmpCause)
                const defaultCause = this.getCookie("defaultCause")

                if (caues.key===defaultCause){
                    const selectedCell={
                        value: caues.value,
                        text: <span><img src={caues.image} className={CLasses.logoCause}/>{caues.value}</span>,
                        key: caues.key,
                    }
                    this.setState({defaultCauseCell:selectedCell})
                    console.log("defaultCauseCell: ",this.state.defaultCauseCell)
                }
            })
           // const defaultCause = this.getCookie("defaultCause")
            console.log(response);
        }).catch(error => {

            console.log(error);
            this.props.history.push("/error");


        }).then(()=>{
            this.setState({loading: false});
        });
    }

    btnAlertClicked = () => {
        this.setState({
            showAlert: false
        });
    };

    addQuery=(url, param)=>{
        if (param === "" || param === undefined
            || url==="" || url===undefined) {
            return url
        }
        url=url+"-"+param
        return url;

    }
    getCookie(key){
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    isRegistred = () => {
        console.log("checking...");
        var hashContent = "";
        var tokenContent = "";
        const cookies = new Cookies();
        hashContent = this.getCookie('userid');
        tokenContent = this.getCookie('token');
        if (hashContent == undefined || tokenContent == undefined) {
            this.props.history.push("/register");

        }
        var auth = {
            userid: hashContent,
            token: tokenContent
        };
        console.log(auth);
        axios.post('/auth', auth).then(response => {
            const useridResponse = response.data.hash;
            const causeResponse = response.data.cause;
            this.setState({
                cause: causeResponse,
                userid: useridResponse
            });
            this.setState({register: true, loading: false});
            console.log(response);
        }).catch(error => {
            this.props.history.push("/register");
            this.setState({register: false});
            console.log(error);
        });

    };
    removeCharAt= (input,i) =>{
        var tmp = input.split(''); // convert to an array
        tmp.splice(i - 1 , 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
        return tmp.join(''); // reconstruct the string
    }
    registerCauseChanged = (event) => {
        console.log(event.target);
        this.setState({
            showAlert: false
        });
        this.cause = event.target.textContent;
        console.log(this.cause);
        let causeNew=this.removeCharAt(this.cause)
        this.causeList.map(cause => {
            if (cause.value.includes(causeNew)) {
                this.setState({cause: cause.key})

            }
        });
    };
    setCookie(key,value){
        const cookies = new Cookies();
        cookies.set(key, value, {path: '/',secure:true,sameSite:'none'});

        localStorage.setItem(key, value);
    }
    goBack(){
        this.props.history.goBack();
    }
    getHostedSite(){
        let domain=window.location.hostname;
        domain= domain.replace('i.','')
        return "https://"+domain+"/"

    }
    setCause() {
        this.setState({loadingApi: true});
        let userCause = {
            userid: "",
            cause: ""
        };
        if (this.cause == "" || this.cause == undefined) {
            const cookies = new Cookies();
            let defaultCause = this.getCookie('defaultCause');
            if (defaultCause == undefined) {
                defaultCause = "gf"
            }
            this.cause = defaultCause;

            return;
        }
        const cookies = new Cookies();
        const userid = this.getCookie('userid');
        userCause.userid = userid;
        console.log(this.causeList);
        this.causeList.map(cause => {
            if (cause.text == this.cause) {
                this.setState({cause: cause.key})

            }
        });
        userCause.cause = this.state.cause;
        console.log("set cause");
        console.log(userCause);

        axios.post('/user/cause', userCause).then(response => {
            console.log(response);
            this.setState({loading: false});
            this.setState({success: true});
            this.setState({loadingApi: false});

            this.showAlert("Success", "Your cause selected.", "success");
           this.setCookie('cause', userCause.cause);
            console.log("cause updated");
            if (this.state.noUrl && this.state.url === "") {
                let userid=this.getCookie('userid')
                let token=this.getCookie('token')
                let domain= this.getHostedSite()+'/c/?userid='+userid+'&token='+token
                window.location.replace(domain)
              //  window.close()
                return;
            } else {
                var url = this.state.url;
                console.log(url);
                if (url !== "" || url !== undefined) {
                    let newUrl;
                    var uri_dec = decodeURIComponent(url);
                    var uriArray = uri_dec.split("&via=");
                    let cause = this.state.cause;
                    let userid = this.state.userid;
                    //newUrl = this.addParam(uriArray[0], "utm_term", cause);
                    //newUrl = this.addParam(newUrl, "user_shared", userid);
                    newUrl=uriArray[0]
                    if(newUrl.charAt(newUrl.length-1)!=="/"){
                        newUrl=newUrl+"/"
                    }
                    newUrl=newUrl+"?q"
                    newUrl=this.addQuery(newUrl,cause)
                    newUrl=this.addQuery(newUrl,userid)
                    if (uriArray.length > 1) {
                        newUrl = newUrl + "&via=" + uriArray[1]
                    }
                    console.log(newUrl);
                    //  newUrl=newUrl.replace("/&utm_term","/?utm_term")
                    window.location.assign(newUrl)
                } else {
                    let userid=this.getCookie('userid')
                    let token=this.getCookie('token')
                    let domain= this.getHostedSite()+'/c/?userid='+userid+'&token='+token
                    window.location.replace(domain)

                    window.close()
                }

            }

        }).catch(error => {
            this.setState({loadingApi: false});

            console.log(error.status);
            if (error.status) {
                this.showAlert("Error", error.response.data.message, "danger");

            } else {
                this.showAlert("Error", "Internal server error, please try again later.", "danger");
            }

        });

    }

    render() {
        let content = "";
        let loading = "";
        console.log("this.state.defaultCauseCell: ",this.state.defaultCauseCell)
        if (this.state.loadingApi) {
            loading = <div className={CLasses.centerDiv}>
                <hr></hr>
                <h6>Please wait...</h6>
                <Spinner className animation="border" variant="success"/>
            </div>
        }
        if (this.state.loading) {
            content = <div className={CLasses.centerDiv}>
                <hr></hr>
                <h6>Please wait...</h6>
                <Spinner className animation="border" variant="success"/>
            </div>
        }
        if (!this.state.loading) {
            content = <Aux>
                <SmallWindowBox>
                    <h4 className={CLasses.centerDiv}>What is your cause?</h4>
                    <hr></hr>
                    <div className={CLasses.centerDiv}>
                        <p>
                            Please select your cause.
                        </p>
                    </div>
                    <br></br>
                    <Form>
                        <Dropdown
                            onChange={this.registerCauseChanged}
                            placeholder="Please Select Cause"
                            fluid
                            search
                            selection
                            defaultValue={this.state.defaultCauseCell.value}
                            options={this.causeList}
                        />
                        {loading}
                        <br></br>
                        <div className={Classes.centerDiv}>
                            <Button className={Classes.buttonWidth}
                                    onClick={this.setCause}
                                    variant="success">
                                Select Cause
                            </Button>
                            <hr></hr>
                            <Button className={Classes.buttonWidth}
                                    onClick={this.goBack}
                                    variant="danger">
                                Back
                            </Button>
                        </div>
                        <AlertDialog
                            show={this.state.showAlert}
                            title={this.alertMessage.title}
                            hideFunc={this.btnAlertClicked}
                            typeClass={this.alertMessage.typeClass}
                            message={this.alertMessage.message}></AlertDialog>
                    </Form>
                </SmallWindowBox>
            </Aux>
        }
        return (
            <Aux>
                {content}
            </Aux>

        );
    }


}

export default withRouter(CauseChange);