import React, {Component} from "react";
import { Col, Container, Form, Row, Spinner} from 'react-bootstrap';
import {Dropdown,Table,Header,Button} from "semantic-ui-react";
import Cookies from "universal-cookie";
import {withRouter} from "react-router-dom";
import axios from "../../axios-agent";
import Class from "./profile.module.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import SmallWindowBox from "../../Components/ui/SmallWindowBox/SmallWindowBox";
import CLasses from "../register/register.module.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
class OldProfile extends Component {
    causeList =  [{
        "value": "Alzheimer's Association",
        "text": "Alzheimer's Association",
        "key": "alz",
        "logo_url": "",
        "image": { avatar: true, src: "https://t.givingforward.org/v1/cause/logo/842933199-356335389.svg" }
    },
    ];
    constructor(props) {
        super(props);
        //............................
        this.state = {
            loading:true,
            avatar:"",
            cause:"",
            first_name:"",
            last_name:"",
            email:"",
            reader_point:0,
            referral_point:0,
            author_point:0,
            logo:""
        }
        this.goChaneCause = this.goChaneCause.bind(this)
        this.goBack=this.goBack.bind(this)
        this.logout=this.logout.bind(this)
    }
    removeCharAt= (input,i) =>{
        var tmp = input.split(''); // convert to an array
        tmp.splice(i - 1 , 1); // remove 1 element from the array (adjusting for non-zero-indexed counts)
        return tmp.join(''); // reconstruct the string
    }
    logout(){
        let domainName=window.location.hostname
        domainName=this.removeCharAt(domainName)
        const cookies = new Cookies();
        cookies.remove("userid", {path: '/',secure:true,sameSite:'none'})
        cookies.remove("token", {path: '/',secure:true,sameSite:'none'})
        cookies.remove("userid", {domain: domainName,path: '/'})
        cookies.remove("token", {domain: domainName,path: '/'})
        localStorage.removeItem("userid");
        localStorage.removeItem("token");
        localStorage.removeItem("cause");

        let domain= this.getHostedSite()+'c/?clear=true'
        window.location.replace(domain)
        //window.close()
    }
    getCookie(key){
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){
            value=cookies.get(key+'-planb');
        }
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    jsUcfirst(string)
    {

        return string.charAt(0).toUpperCase() + string.slice(1);

    }
    goBack() {

        this.props.history.goBack();
    }
    goChaneCause(){
        this.props.history.push("/cause");
    }
    getHostedSite(){
        let domain=window.location.hostname;
        domain= domain.replace('i.','')
        return "https://"+domain+"/"

    }
    componentDidMount() {
        const userid=this.getCookie("userid")
        const token=this.getCookie("token")
        if (userid===undefined|| token===undefined){
            this.props.history.push("/register");
        }
        var auth = {
            userid: userid,
            token: token
        };
        console.log(auth)
        axios.get('/cause').then(response => {
            this.causeList = response.data;
            console.log(response);
        }).catch(error => {
            console.log(error);
            this.props.history.push("/error");


        });
        axios.post('/auth', auth).then(response => {
            const urlLogo="https://dev.givingforward.org/logo/"+response.data.cause+".svg"

            let name = "Dear friend";
            let cause=""
            console.log(response)
            if (response.data.display_name != "") {
                const names = response.data.display_name.split(' ');
                name = names[0];
                name=this.jsUcfirst(name);

            }
            this.causeList.map(c=>{
                if (c.key===response.data.cause){
                    cause=c.value
                    console.log(cause);
                }
            });

            this.setState({
                loading:false,
                first_name:name,
                lastname:response.data.last_name,
                avatar:response.data.avatar,
                last_name:response.data.last_name,
                email:response.data.email,
                reader_point:response.data.reader_point,
                referral_point:response.data.referral_point,
                cause:cause,
                author_point:response.data.author_point,
                logo:urlLogo
            })
        }).catch(error => {
            //this.props.history.push("/register");
        })

    }



        render()
        {
            let authorPointCell
            let content;
            let loading;
            if (this.state.loading) {
                loading = <div className={Class.center} key={"loading"} >
                    <hr></hr>
                    <h6>Please wait...</h6>
                    <Spinner className animation="border" variant="success"/>
                </div>
            }else {
                if (this.state.author_point !==0){
                    authorPointCell= <Table.Row>
                        <Table.Cell>
                            <Header as='h4' >
                                <Header.Content>
                                    Author Points
                                    <Header.Subheader>You get six points every time an article you write on our platform is read.</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Table.Cell>
                        <Table.Cell>{this.state.author_point}</Table.Cell>
                    </Table.Row>
                }

                content=      <div >
                    <div className={Class.center}>
                        <div className={Class.center}>
                            <img
                                src={this.state.avatar}
                                name="aboutme" width="140" height="140" border="0" style={{borderRadius:"50%"}}>
                            </img>

                            <h3 className="media-heading">Hi {this.state.first_name}</h3>
                            <span><strong>Email: {this.state.email} </strong></span>
                            <br></br>

                            <span><strong>Cause: </strong></span>
                            <span className="label label-warning">{this.state.cause}</span>
                            <br></br>
                            <img
                                src={this.state.logo} alt={this.state.cause} className={Class.causeLogo}>

                            </img>
                            <br></br>

                        </div>
                        <hr></hr>
                        <div className={Class.center}>
                            <Table basic='very' celled collapsing >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Type of points</Table.HeaderCell>
                                        <Table.HeaderCell>Amount of points</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>
                                            <Header as='h4' >
                                                <Header.Content>
                                                    Reader Points
                                                    <Header.Subheader>You get six points each time you read or watch content on a Giving Forward site.</Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                        </Table.Cell>
                                        <Table.Cell>{this.state.reader_point}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                                <Table.Row>
                                    <Table.Cell>
                                        <Header as='h4' >
                                            <Header.Content>
                                                Referral Points
                                                <Header.Subheader>You get six points every time something you share on social media is read via your share on our platform.</Header.Subheader>
                                            </Header.Content>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>{this.state.referral_point}</Table.Cell>
                                </Table.Row>
                            </Table>
                            <br></br>
                            <Button basic color='blue' onClick={() => {
                                window.close()
                            }}>
                                Close
                            </Button>
                            <Button basic color='green' onClick={this.goChaneCause}>
                                Change Cause
                            </Button>
                        </div>
                        <br></br>
                    </div>
                    <div className="modal-footer">
                        <div className={Class.center}>
                            <Button basic color='red' fluid onClick={this.logout}>
                                Logout
                            </Button>
                        </div>
                    </div>
                </div>
            }

            return (
                <div>
                    {content}
                    {loading}


                </div>

            )

        }

}

export default withRouter(OldProfile);