import React, {Component} from "react";
import './notfound.module.css';
import Classes from "./notfound.module.css";

class MoonNotfound extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        return (
            <div className={Classes.bodyCLass}>
                <div className={Classes.signwood}></div>
                <div className={Classes.signtext}>
                    <p>404<br/>Not found!</p>
                </div>
                <div className={Classes.pin}></div>

                <div className={Classes.line}></div>
            </div>
        )
    };
}

export default MoonNotfound;