import React from "react";
import {Col, Container, Nav, Navbar, Row} from 'react-bootstrap';
import CLasses from './UserData.module.css';
import LogoTop from "../../../assets/images/logo512.png";
import FollowButton from "../FollowButton/FollowButton";
const UserData = (props) => {
    const urlLogo="https://dev.givingforward.org/logo/"+props.cause+".svg"
    return (
        <div>
            <Container className={CLasses.contsiner}>


                <Col md="auto"><b>Monthly points: </b>{props.dayilypoint}</Col>
                <Col md="auto"><b>Cause: </b>
                    <img
                        src={urlLogo} alt={props.cause} className={CLasses.avatar}>

                    </img>
                    <a style={{textDecoration: "underline",paddingLeft:"5px"}}
                       href="https://www.givingforward.org/about-giving-forward/donations-how-it-works/" target="_blank">DETAILS</a>
                    </Col>
                <Col md="auto">                <FollowButton></FollowButton>
                </Col>


            </Container>
        </div>
    );
};
export default UserData;