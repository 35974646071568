import React, {useState,useEffect} from "react";
import {Alert, Button, Form, Spinner} from 'react-bootstrap';
import {Dropdown} from "semantic-ui-react";
import CauseCards from "./CauseCard/CuaseCard";
import Classes from './featured-selected.module.css'
import CLasses from "../../Pages/register/register.module.css";
import Cookies from "universal-cookie";
function MultiSelect(props) {
    const [causeSelected,setCause] = useState("Giving Forward")
    const [causeKey,setCauseKey] = useState('gf')
    const [logoUrl,setLogoUrl]=useState('https://t.givingforward.org/v1/cause/logo/842933199-356335389.svg')
    const [loading,setLoading] =useState(true)
    const [causeList,SetCauseList] = useState([])
    const [Content,SetContent]=useState("")
    const getCookie=(key)=>{
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    useEffect(()=>{
        var causeTemp="gf"
        let defaultCause = getCookie('defaultCause');
        if (defaultCause){
            causeTemp=defaultCause;
        }else {
            causeTemp="gf"
        }
        setCauseKey(causeTemp)
        SetContent(<div className={CLasses.centerDiv}>
            <hr></hr>
            <h6>Please wait...</h6>
            <Spinner className animation="border" variant="success"/>
        </div>)
    },[])
    useEffect(()=>{
        SetCauseList(props.causeList)
    },[props.causeList])

    useEffect(()=>{
        props.causeList.map(tempCause => {
            if (tempCause.key.includes(causeKey)) {
                console.log("tempCause.value: =",tempCause.value)
                setLogoUrl(`https://dev.givingforward.org/logo/${tempCause.key}.svg`)
                setCause(tempCause.value)
            }
        });
    },[causeKey,props.causeList,causeList,loading])



    useEffect(()=>{
        causeList.map(tempCause => {
            if (tempCause.value.includes(causeSelected)) {

                setCauseKey(tempCause.key)
                console.log(causeKey)
                setLogoUrl(`https://dev.givingforward.org/logo/${tempCause.key}.svg`)
                props.causeChanged(tempCause.key)

            }
        });
    },[causeSelected])
    useEffect(()=>{
        console.log(props.causeList.length)
      if (props.feautredCauseList.length!==0  && props.causeList!==0){
          setLoading(false)
          console.log("Loading end")
      }
    })

    const changeCauseHandler=(event)=>{
        //const changedCause=removeCharAt(event.target.textContent)
        setCause(event.target.textContent)
    }
    const featuredSelectHandler=(value)=>{
        setCause(value)
    }

    useEffect(()=>{
        if (!loading) {
            SetContent(<div >
                <div>
                    <p style={{fontSize:"medium"}}>
                        Please select cause, your default cause is: <span><img src={logoUrl} className={Classes.logoCause}/>{causeSelected}</span>
                    </p>
                    <hr></hr>
                </div>
                <CauseCards selectCause={featuredSelectHandler} feautredCauses={props.feautredCauseList}></CauseCards>

                {causeSelected && <Dropdown
                    onChange={changeCauseHandler.bind(this)}
                    placeholder="You search for more cause here..."
                    fluid
                    search
                    selection
                    options={causeList}
                >
                </Dropdown>}
            </div>)
        }
    },[loading,props.causeList,props.feautredCauseList,causeList,causeKey,causeSelected])
    return(
        <div>
            {Content}
        </div>


    );

}
export default MultiSelect;