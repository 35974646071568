import Classes from "./Success.module.css";
import React from "react";
import {Button} from "react-bootstrap";
import {withRouter,useLocation} from "react-router-dom";
import Cookies from "universal-cookie";

const Success = (props) => {
    let setCookie=(key,value)=>{
        const cookies = new Cookies();
        cookies.set(key, value, {path: '/',secure:true,sameSite:'none'});
        cookies.set(key, value, {path: '.givingforward.org',secure:true,sameSite:'none'});
        localStorage.setItem(key, value);
    }

    setCookie('userid',props.match.params.id)
    setCookie('token',props.match.params.token)
    return (
        <div className={Classes.rcorners3}>
            <div className={Classes.centerDiv}>
                <h3>Congratulations</h3>
                <p>Your registration is complete.</p>
                <Button onClick={() => {
                    window.close()
                }} variant="success">Close</Button>
            </div>
        </div>
    );
}
export default withRouter(Success);