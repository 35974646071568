import React, {Component} from "react";
import Aux from '../../hoc/Auxilary/Auxilary';
import Cookies from "universal-cookie";
import axios from "../../axios-agent";
import  { Modal,Button, Checkbox, Form,Image,Header } from 'semantic-ui-react'
import email from '../../assets/images/email.jpg'
import Classes from ".//email.module.css"
import Spinner from "react-bootstrap/Spinner";
import EmailForm from "./EmailForm/emailForm";
import AlertDialog from "../../Components/ui/alert/alert";
import SmallWindowBox from "../../Components/ui/SmallWindowBox/SmallWindowBox";
class Email extends Component {
    alertMessage = {
        title: "",
        typeClass: "success",
        message: ""
    };
    state = {
        setOpen:false,
        open:false,
        loading:true,
        showAlert:false,
        reqLoading:false

    }
    emailMessage = {
        userid: "",
        header:"",
        title:"",
        text:"",
        button:"",
        regards:"",
        url_hint:"",
        footer:"",
        copy_write:""
    };
    btnAlertClicked = () => {
        this.setState({
            showAlert: false
        });
    };
    showAlert(title, message, type) {

        this.alertMessage.message = message;
        this.alertMessage.title = title;
        this.alertMessage.typeClass = type;
        this.setState({
            showAlert: true
        });
    }
    copy_writeChanged = (event) => {

        // this.setState({userState:this.userSite})
        this.emailMessage.copy_write = event.target.value;
    };
    footerChanged = (event) => {

        // this.setState({userState:this.userSite})
        this.emailMessage.footer = event.target.value;
    };
    url_hintChanged = (event) => {

        // this.setState({userState:this.userSite})
        this.emailMessage.url_hint = event.target.value;
    };
    regardsChanged = (event) => {

        // this.setState({userState:this.userSite})
        this.emailMessage.regards = event.target.value;
    };
    buttonChanged = (event) => {

        // this.setState({userState:this.userSite})
        this.emailMessage.button = event.target.value;
    };
    EmailChanged = (event) => {

        // this.setState({userState:this.userSite})
        this.emailMessage.email = event.target.value;
    };
    HeaderChanged = (event) => {

        // this.setState({userState:this.userSite})
        this.emailMessage.header = event.target.value;
    };
    TitleChanged = (event) => {

        // this.setState({userState:this.userSite})
        this.emailMessage.title = event.target.value;
    };
    textChanged = (event) => {

        // this.setState({userState:this.userSite})
        this.emailMessage.text = event.target.value;
    };
    componentDidMount() {
        this.isRegistered();
    }
    getCookie(key){
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }
    redirect = () => {
        window.location.assign(window.location.hostname)

    }
    sendRequest=()=>{
        if (this.emailMessage.button===""&& this.emailMessage.title===""&&this.emailMessage.text===""&&
            this.emailMessage.header===""&&this.emailMessage.footer===""&&this.emailMessage.url_hint===""){
            this.showAlert("Error", "Enter at least one of the values.", "danger");

        }else {

            let hashContent =  this.getCookie('userid');
            this.emailMessage.userid= hashContent;
            this.setState({reqLoading: true});
            axios.post('email',this.emailMessage).then(response=>{
                this.setState({reqLoading: false});
                this.showAlert("Success", response.data.message, "success");
            }).catch(error=>{
                console.log(error);
                if (error.status) {
                    this.showAlert("Error", error.response.data.message, "danger");
                } else {
                    this.showAlert("Error", "Internal server error, please try again later.", "danger");

                }
                this.setState({reqLoading: false});
            });
        }
    }
    isRegistered = () => {
        this.setState({loading: true});

        var hashContent = "";
        var tokenContent = "";
        hashContent =  this.getCookie('userid');
        tokenContent =  this.getCookie('token');
        var auth = {
            userid: hashContent,
            token: tokenContent,
            path:"email"
        };
        const token=tokenContent;
        if (token != undefined && token != "") {
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + token;
        }
        axios.post('/auth', auth).then(response => {
            this.setState({ loading: false});


        }).catch(error => {
            this.setState({ loading: false});
            this.redirect()
            console.log(error);

        });

    };

    render() {

        let content=""
        let reqLoading=""
        if (this.state.reqLoading){
            reqLoading= <Spinner className animation="border" variant="success"/>
        }
        if (this.state.loading) {
            content = <div className={Classes.centerDiv}>
                <h6>Please wait...</h6>
                <Spinner className animation="border" variant="success"/>
            </div>;
        }else {
            content=<div>
                <EmailForm
                    headerChange={this.HeaderChanged}
                    titleChange={this.TitleChanged}
                    textChange={this.textChanged}
                    buttonChange={this.buttonChanged}
                    regardsChange={this.regardsChanged}
                    url_hintChange={this.url_hintChanged}
                    footerChange={this.footerChanged}
                    copy_writeChange={this.copy_writeChanged}
                    header={this.emailMessage.header}
                    title={this.emailMessage.title}
                    text={this.emailMessage.text}
                    button={this.emailMessage.button}
                    regards={this.emailMessage.regards}
                    url_hint={this.emailMessage.url_hint}
                    footer={this.emailMessage.footer}
                    copy_write={this.emailMessage.copy_write}
                    sendRequest={this.sendRequest}
                />
                {reqLoading}
                <AlertDialog
                    show={this.state.showAlert}
                    title={this.alertMessage.title}
                    hideFunc={this.btnAlertClicked}
                    typeClass={this.alertMessage.typeClass}
                    message={this.alertMessage.message}
                />
            </div>
        ;
        }
        return(
            <Aux>
                {content}

        </Aux>);
    }
}
export default Email;