import React, {useEffect, useState} from 'react'
import {Button, Dropdown} from 'semantic-ui-react'
import {Form} from "react-bootstrap";
import Cookies from "universal-cookie";
import axios from "../../../axios-agent";
//http://localhost:3000/?url=https://goodbuzz.org/adriana-paniagua/what-does-it-mean-to-you-to-be-happy-jack-livaditis-shares-with-adriana-paniagua/
const FollowButton = (props) => {
    const [isInUrl,setIsInUrl] = useState(false)
    // const [followMessage,setFollowMessage] = useState("Follow")
    const [followState,setFollowState] = useState({state:false,message:"Follow"})

    // const [isFollowing,setIsFollowing]= useState(false)
    const [disableBtn,setDisableBtn] = useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [authorSlug,setAuthorSlug]=useState("")
    const [userid,setUserid]=useState("")
    const [buttonFollow,setButtonFollow]=useState(null)
    const [authorName,setAuthorName]=useState("")
    useEffect(()=>{
        let url = window.document.URL;
        let sections=url.split("//");
        if (sections.length>2){
            setIsInUrl(true)
            let last = sections[2]
            let second=last.split("/")
            if (second.length>1){
                let username=second[1]
                let id=getCookie("userid")
                setAuthorSlug(username);
                if (id!=="" && username!==""){
                    axios.get("follow/valid/"+id+"/"+username).then(response=>{
                        // setIsFollowing(true)
                        // setFollowMessage("Following")
                        setFollowState({state: true,message: "Following"})
                    }).catch(error=>{
                        // setIsFollowing(false)
                        setFollowState({state: false,message: "Follow"})

                    })
                }
            }else {
                setIsInUrl(false)
            }

        }else {
            setIsInUrl(false)
        }
        let id=getCookie("userid")
        setUserid(id);



    },[])
    useEffect(()=>{
        console.log(userid)
    },[authorSlug,userid])
    useEffect(()=>{
        if(authorSlug!==""){
            let pathSlug="/user/slug/"+authorSlug
            setIsLoading(true)
            axios.get(pathSlug).then(response => {
                console.log(response.data.message)
                setIsLoading(false)
                setAuthorName(response.data.message)
            }).catch(error => {
                console.log(error)
                setIsLoading(false)

                setAuthorName("Author")
               // setDisableBtn(true)
            });
        }

    },[authorSlug])

    const followAuthor=()=> {

        if(authorSlug==="" || userid ==="" || followState.state){
            return
        }
        let followModel={
            "author_slug":authorSlug,
            "userid":userid
        };
        setIsLoading(true)
        axios.post('/user/'+userid+'/follow', followModel).then(response => {

            setIsLoading(false)
            setFollowState({state: true,message: "Following"})


        }).catch(error => {
            setIsLoading(false)
            setFollowState({state: false,message: "Follow"})


        });

    }

    useEffect(()=>{
        if(authorName!==""){
            setButtonFollow(
                <Button  disabled={disableBtn} size='mini'  primary={followState.state} positive={!followState.state} loading={isLoading} onClick={followAuthor}>{followState.message} {authorName}</Button>
            )
        }
    },[authorName,followState,authorSlug,isLoading,disableBtn])
    //..........................
   const getCookie=(key)=>{
        const cookies = new Cookies();
        let value = cookies.get(key);
        if (value==="" || value===undefined){

            value=cookies.get(key+'-planb');
        }
        if (value==="" || value===undefined){
            value=localStorage.getItem(key);
        }
        return value;
    }


    return(
        <div>
            {buttonFollow}
    </div>)
}
export default FollowButton;
